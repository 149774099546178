import React from 'react';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
import './m_mask.css';

class OtherMatcha_mask extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main className="matcha-mask">
{/*			<Container>
				<div className="head-logo sec">
					<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
				</div>

					<div className="pan">
						<div><a href="/">TOP</a></div>
						<div>贅沢美肌堂（抹茶de美肌）</div>

					</div>
</Container>*/}

{/*<Container fluid={true} className="bg_zeneral">
	<Container>
	    <Row>
				<picture>
				<source media="(max-width: 700px)" srcSet="/img/others/maccha/maccha_one_sp.jpg" />
				<Image src="/img/others/maccha/maccha_one_pc.jpg" alt="フェイスパック部門で3つの1位を獲得しました！" fluid />
			</picture>
				    </Row>
</Container>
	</Container>*/}

	<Container>
	    <Row>
	    <Col md={4} className="obon pt-5">
	<Image src="/img/others/maccha/mask/head_txt_mask.png" alt="自然の力で、もっと輝ける自分に。" className="pt-5" fluid />
<p className="text-center text-lg pt-5">日本古来の植物成分にこだわり、<br />
肌への優れたアプローチを<br />追求する化粧品ブランド。<br />
「贅沢美肌堂」<br />
澄みきった、透明感のある美しい肌へ。</p>
	</Col>
	<Col md={8}>
<Image src="/img/others/maccha/mask/main_mask.jpg" alt="抹茶de美肌 プレミアムシートマスク" fluid />
	</Col>
	    </Row>
</Container>
{/*
<Container>
	<h3><span>お使いいただいた方の声</span></h3>
	<Row>
<Col sm={6} md={3}><p className="voice">流すとお肌の手触りがすべすべ。お風呂上がりにはしっとり柔らか！</p></Col>
<Col sm={6} md={3}><p className="voice">肌がワントーン明るくなった気がします。翌日の化粧ノリがいいです</p></Col>
<Col sm={6} md={3}><p className="voice">柔らかめのテクスチャーだけど垂れないし、伸びが良くて使いやすいのがいい</p></Col>
<Col sm={6} md={3}><p className="voice">グリーンのパック姿が子供達に好評で 、お風呂タイムに楽しくケアできました</p></Col>
	</Row>
	<p className="text-sm text-right">※個人の方の使用後の感想です</p>
</Container>*/}


<Container fluid={true} className="maccha_bg">
<Container>
  <Row>
<Col xs={12} sm={6} className="macchatxt"><Image src="/img/others/maccha/txt_maccha.png" alt="抹茶はチアシードやキヌアに並ぶ日本のスーパーフード"  /></Col>
<Col xs={12} sm={6} className="pt-5">世界の健康・美容意識の高い人々からは「matcha」として英語表記でも認識されており、チアシードやキヌア等と並び、スーパーフードとして非常に高い関心を集めています。</Col>
<Col sm={12} className="pt-5"><picture>
<source media="(max-width: 600px)" srcSet="/img/others/maccha/img_maccha_sp.png" />
<Image src="/img/others/maccha/img_maccha_pc.png" alt="抹茶に含まれる栄養素" fluid /></picture>
</Col>
  </Row>
</Container>
</Container>

<Container className="pt-5 pb-5">
  <Row>
<Col xs={12} sm={6} className="maccha-catch pb-3"><strong>「抹茶de美肌」では人気の高い国産茶葉ブランド<span className="green">「京都宇治抹茶」を使用</span>し、
	厳正な管理のもと栽培されている、<span className="green">100％オーガニック茶葉</span>にこだわりました。</strong></Col>
<Col xs={6} sm={3}><Image src="/img/others/maccha/img_maccha02.jpg" fluid  /></Col>
<Col xs={6} sm={3}><Image src="/img/others/maccha/img_maccha03.jpg" fluid  /></Col>
  </Row>
</Container>

<Container className="pt-5 pb-5 point">
<h2 className=""><Image src="/img/others/maccha/mask/leaf.png" alt="point" className="pb-3" /><br />
美肌のための<br />
<span className="green">厳選成分</span>を配合</h2>
<div className="point1">
<h3><Image src="/img/others/maccha/mask/point1.png" alt="1" />国産宇治W茶成分でうるおいと透明感アップ</h3>
<Row>
<Col xs={12} sm={{ span: 5, offset: 1 }} className="pt-5"><p>抹茶にはビタミン・ミネラルなどの成分が含まれており、世界でも<span className="green">「matcha」</span>として美容の観点から注目されています。<br />
京都宇治茶の100％オーガニック抹茶パウダー<span className="kome">※1</span>と緑茶エキス<span className="kome">※2</span>配合で<span className="green">うるおいと透明感のある肌</span>へ導きます。</p>
<p><small>※1 チャ葉　※2 チャ葉エキス 〈全て保湿成分〉</small></p></Col>
<Col xs={12} sm={6} className=""><Image src="/img/others/maccha/mask/img_point01.jpg" fluid /></Col>
</Row>
<span className="line_gry"></span>
</div>
<div className="point2">
<h3><Image src="/img/others/maccha/mask/point2.png" alt="2" />5種の発酵成分でつるんともちもち美肌に</h3>
<p className="point2_txt">日本の美容には古くから「発酵」が深く関わってきました。<br />
そんな発酵のチカラに着目し、<span className="pink">5種の発酵成分</span>を独自にブレンド。<br />
肌をすこやかに保ち、つるんとキメの整った<span className="pink">もっちり美肌</span>に。</p>
<Row>
<ul className="text-center my-5">
<li><Image src="/img/others/maccha/mask/material_01.png" alt="コメ発酵液" fluid /><p>コメ発酵液</p></li>
<li><Image src="/img/others/maccha/mask/material_02.png" alt="豆乳発酵液" fluid /><p>豆乳発酵液<span className="kome">※3</span></p></li>
<li><Image src="/img/others/maccha/mask/material_03.png" alt="コラーゲン発酵液" fluid /><p>コラーゲン発酵液<span className="kome">※4</span></p></li>
<li><Image src="/img/others/maccha/mask/material_04.png" alt="ヨーグルト液" fluid /><p>ヨーグルト液<span className="kome">※5</span></p></li>
<li><Image src="/img/others/maccha/mask/material_05.png" alt="フルーツ果汁発酵液" fluid /><p>フルーツ果汁発酵液<span className="kome">※6</span></p></li>
</ul>
</Row>
<p className="pb-5"><small>※3 乳酸桿菌/豆乳発酵液　※4 アスペルギルス培養物　※5 ヨーグルト液(牛乳)　※6 乳酸桿菌/セイヨウナシ果汁発酵液 〈全て保湿成分〉</small></p>
<span className="line_gry"></span>
</div>

<div className="point3">
<h3><Image src="/img/others/maccha/mask/point3.png" alt="3" />うるおい成分のヒアルロン酸<span className="kome">※7</span>でハリ・ツヤ</h3>
<Row>
<Col xs={12} sm={{ span: 5, offset: 1 }} className="pt-5"><p>水分を抱え込んでお肌に<span className="blue">うるおい</span>を与え、<br />
<span className="blue">ハリ・弾力</span>を生み出し乾燥を防ぎます。</p>
<p className=""><small>※7 ヒアルロン酸Na　〈保湿成分〉</small></p></Col>
<Col xs={12} sm={5} className="pb-5"><Image src="/img/others/maccha/mask/img_point03.jpg" fluid /></Col>
</Row>
</div>
</Container>

<Container fluid={true} className="bg_green details">
	<Container>
	<h2 className="white">DETAILS</h2>
	<h4 className="green text-center">細部にもこだわった贅沢なシートマスク</h4>
	    <Row>
				<Col xs={12} sm={6} className="maskimg"><Image src="/img/others/maccha/mask/mask.png" alt="シートマスク" fluid /></Col>
				<Col xs={12} sm={6} className="mb-5 pb-5"><div><h3 className="right_pc"><span>贅沢</span>な濃厚美容液＋超極厚シート</h3>
				<p>国産の厚みのあるシートが濃厚な美容液をたっぷりと抱え込み、液だれしにくくしっかりと顔にフィットします。<br />
忙しい女性の“ながら使い”にもおすすめ。</p></div></Col>
				    </Row>
						<Row>
							<Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 1 }} className="mb-5"><div className="pt-5"><h3><span>衛生的</span>な個包装パウチ</h3>
							<p>マスク１枚ずつを個包装したパウチは衛生的。<br />
旅行先で友人とシェアしたり、ちょっとしたギフトなどにも最適です。</p></div></Col>
			<Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 2 }}><Image src="/img/others/maccha/mask/bunpou.png" alt="個包装パウチ" fluid /></Col>
									</Row>
</Container>
	</Container>


<Container className="howto-content my-5 py-5">
	<h2 className="green"><span>ご使用方法</span></h2>
	<div className="text-center mb-5 pb-5"><p><span>《使用目安：週1～2回》</span></p>
	<p>美容液がシートにまんべんなくいきわたるように、両手で袋全体を軽くもんでから開封してください。</p></div>
	<Row><Col xs={12} sm={4}><Image src="/img/others/maccha/mask/step01.png" fluid  /><p className="mb-5">袋からマスクを取り出して広げ、シートの目元部分を外側に折り返します。</p></Col>
	<Col xs={12} sm={4}><Image src="/img/others/maccha/mask/step02.png" fluid  /><p className="mb-5">目・口の位置を合わせて顔全体にフィットさせ、折り返した部分を目元に密着させます。</p></Col>
	<Col xs={12} sm={4}><Image src="/img/others/maccha/mask/step03.png" fluid  /><p className="mb-5">5分ほどおいてからマスクを取り、顔に残った美容液をよくなじませてください。</p></Col>
	</Row>
</Container>

<Container className="maccha_footer text-center">
		<Row><Col xs={12} sm={7} className="mt-5"><Image src="/img/others/maccha/mask/img_product_msc.jpg" alt="抹茶de美肌 プレミアムシートマスク" fluid /></Col>
		<Col xs={12} sm={5}><p className=""><Image src="/img/others/maccha/mask/logo_maccha_m.svg" alt="抹茶de美肌 プレミアムシートマスク" className="logo_maccha_m" /></p>
		<p className="mb-5"><Image src="/img/others/maccha/mask/smell.svg" alt="抹茶ミルクの香り" /></p>
		<p className="mb-5 txt_lg font_mch">《4枚入り》</p>
		<p className="mt-5 text-center"><a href="/item/94841/" className="btn btn-lg macchabtn py-3"><i className="fa fa-shopping-cart"></i> ご購入はこちらから</a></p>
		</Col></Row>
		<a href="/item/94329/" className="opa06 link_pack"><Image src="/img/others/maccha/mask/link_pack.png" className="" /></a>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OtherMatcha_mask);
