import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
	Card,
	Image,
} from 'react-bootstrap';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';

export function withAuthenticator(WrappedComponent) {

		return class PP extends React.Component {
			constructor(props) {
				super(props);
				this.state = {
					isAuth    : undefined,
					isProfile : undefined,
				};
				this._reload = this._reload.bind(this);
				this._auth   = this._auth.bind(this);
			}
			async _auth(){
				try {
					await API.Auth.currentAuthenticatedUser();
					return(true);
				}
				catch(error) {
					return(false);
				}
			}
			async _reload() {
				this.setState({
					isAuth    : undefined,
					isProfile : undefined,
				});

				//ログインと会員登録フラグ
				let isAuth    = false;
				let isProfile = false;

				//認証されてるか
				const ref = await this._auth();
				if(ref){
					isAuth = true;
				}

				//会員データを取得
				const refProfile = await API.Profile.Get();

				//データがあるか
				if(refProfile.item !== null){
					isProfile = true;
				}
				//console.log(refProfile);

				this.setState({
					isAuth    : isAuth,
					isProfile : isProfile,
				});
			}
			componentDidMount () {
				this._reload();
			}
			render() {
				if(this.state.isAuth === false ){
					return <SignIn reload={this._reload} />
				}
				else if(this.state.isProfile === false ){
					return <Profile reload={this._reload} />
				}
				else if(this.state.isAuth === true ){
					return <WrappedComponent {...this.props} reload={this._reload} />
				}
				else{
					return <div />
				}
			}
		}
}

export class SignInExport extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading               : false,
			isValidated             : false,
			isLockForm              : false,
			ErrMsg                  : '',
			email                   : '',
			isChallengeResponses    : false,
			isPasswordResetRequired : false,
			isForgotPassword        : false,
		}
		this._reload       = this._reload.bind(this);
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.lockFormClose = this.lockFormClose.bind(this);
	}
	_reload() {
		this.setState({ isLoading: true });
		this.props.reload();
	}
	async handleSubmit (event) {

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated : true,
			isLockForm  : true,
		});

		//Formオブジェクトの確認
		const formObj     = event.currentTarget;
		if(!formObj){
			this.setState({
				ErrMsg     : '※不正なエラーが発生しました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Formオブジェクトの確認
		if(formObj.checkValidity() === false){
			this.setState({
				ErrMsg     : '※入力項目にエラーがありました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Formから直接取得する更新項目
		const email         = formObj.email.value;
		const passwd        = formObj.passwd.value;

		//更新処理
		try{
			//サインイン
			const result = await API.Auth.signIn(email, passwd);

			//パスワードの変更要求があった場合
			if(result.challengeName === 'NEW_PASSWORD_REQUIRED'){
				this.setState({
					isChallengeResponses: true,
					email               : email,
				});
				return(0);
			}
		}
		catch(error) {
			//console.log(error);
			//パスワードのリセットがあった場合
			if(error.code === 'PasswordResetRequiredException'){
				this.setState({
					isPasswordResetRequired: true,
					email                  : email,
				});
				return(0);
			}
			//パスワードが正しくない場合
			if(error.code === 'NotAuthorizedException'){
				this.setState({
					ErrMsg     : 'メールアドレス、またはパスワードが正しくありません。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			//パスワードが正しくない場合
			if(error.code === 'UserNotFoundException'){
				this.setState({
					ErrMsg     : 'メールアドレス、またはパスワードが正しくありません。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}
		//更新成功
		this.setState({
			isLockForm  : false,
		});
		this.props.reload();
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		if(this.state.isPasswordResetRequired){
			return (
				<PasswordResetRequired email={this.state.email} reload={this._reload} />
			);
		}
		else if(this.state.isChallengeResponses){
			return (
				<ChangePassword email={this.state.email} reload={this._reload} />
			);
		}
		else if(this.state.isForgotPassword){
			return (
				<ForgotPassword email={this.state.email} reload={this._reload} />
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss}>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
					<Container>
						<h1>SHOPPING CART<p>ショッピングカート</p></h1>
						<Image src="/img/step_order01.svg" className="img-fluid te mx-auto d-block"/>
						<h2>購入手続き</h2>
						<h3><span>お届け先をご入力ください</span></h3>
						<Row>
							<Col md={{ span: 10, offset: 1 }}>
								<p>会員の方はログインしていただくと、ご注文者情報などの入力を省略できて便利です。</p>
								<Card>
									<Card.Body>
										<h4 className="card-title"><span>会員のお客様</span></h4>
										<p>ご登録いただいたメールアドレスとパスワードでログインしてください。</p>

										<Row>
											<Col sm={12} md={6}>
												<Form.Group controlId="emailAuth">
													<Form.Label>メールアドレス</Form.Label>
													<Form.Control name="email" type="email" placeholder="Eメールアドレス" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														「メールアドレス」を正しく入力してください。
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
												<Col sm={12} md={6}>
												<Form.Group controlId="passwdAuth">
													<Form.Label>パスワード</Form.Label>
													<Form.Control name="passwd" type="password" placeholder="パスワード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														「パスワード」を正しく入力してください。
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
										<Button className="btn-pink" type="submit" block size="lg" disabled={this.state.isLockForm}>ログイン</Button>
										<a href="/login/">※パスワードをお忘れですか？</a>
											<p className="red">※Internet Explorer11をご利用のお客様は、ログイン処理に時間がかかる場合がございます。少々お待ちください。また、Microsoft EdgeやGoogle Chromeなどのブラウザがおすすめです。</p>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
					</Form>
				</div>
			);
		}
	}
}

class SignIn extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading               : false,
			isValidated             : false,
			isLockForm              : false,
			ErrMsg                  : '',
			email                   : '',
			isChallengeResponses    : false,
			isPasswordResetRequired : false,
			isForgotPassword        : false,
		}
		this._reload       = this._reload.bind(this);
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.lockFormClose = this.lockFormClose.bind(this);
	}
	_reload() {
		this.setState({ isLoading: true });
		this.props.reload();
	}
	async handleSubmit (event) {

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated : true,
			isLockForm  : true,
		});

		//Formオブジェクトの確認
		const formObj     = event.currentTarget;
		if(!formObj){
			this.setState({
				ErrMsg     : '※不正なエラーが発生しました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Formオブジェクトの確認
		if(formObj.checkValidity() === false){
			this.setState({
				ErrMsg     : '※入力項目にエラーがありました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Formから直接取得する更新項目
		const email         = formObj.email.value;
		const passwd        = formObj.passwd.value;

		//更新処理
		try{
			//サインイン
			const result = await API.Auth.signIn(email, passwd);

			//パスワードの変更要求があった場合
			if(result.challengeName === 'NEW_PASSWORD_REQUIRED'){
				this.setState({
					isChallengeResponses: true,
					email               : email,
				});
				return(0);
			}
		}
		catch(error) {
			//console.log(error);
			//パスワードのリセットがあった場合
			if(error.code === 'PasswordResetRequiredException'){
				this.setState({
					isPasswordResetRequired: true,
					email                  : email,
				});
				return(0);
			}
			//パスワードが正しくない場合
			if(error.code === 'NotAuthorizedException'){
				this.setState({
					ErrMsg     : 'メールアドレス、またはパスワードが正しくありません。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			//パスワードが正しくない場合
			if(error.code === 'UserNotFoundException'){
				this.setState({
					ErrMsg     : 'メールアドレス、またはパスワードが正しくありません。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}
		//更新成功
		this.setState({
			isLockForm  : false,
		});
		this.props.reload();
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		if(this.state.isPasswordResetRequired){
			return (
				<PasswordResetRequired email={this.state.email} reload={this._reload} />
			);
		}
		else if(this.state.isChallengeResponses){
			return (
				<ChangePassword email={this.state.email} reload={this._reload} />
			);
		}
		else if(this.state.isForgotPassword){
			return (
				<ForgotPassword email={this.state.email} reload={this._reload} />
			);
		}
		else{
			return (
				<div>
					<Header/>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss}>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<main>
					<div className="head-logo sec">
						<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
					</div>
						<Container>
							<div className="pan d-flex justify-content-end">
								<div><a href="/">TOP</a></div>
								<div><a href="/">会員様ログイン</a></div>
							</div>
						</Container>
						<Container>
							<h1>
								LOGIN
								<p>
									会員様ログイン
								</p>
							</h1>
							<Row>
								<Col sm={12} md={6}>
									<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
									<Card className="h-100">
										<Card.Body>
											<Card.Title>
												<span>会員のお客様</span>
											</Card.Title>
											<p>ご登録いただいたメールアドレスとパスワードでログインしてください。</p>
											<Form.Group controlId="email">
												<Form.Label>メールアドレス</Form.Label>
												<Form.Control name="email" type="email" placeholder="Eメールアドレス" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													「メールアドレス」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group controlId="passwd">
												<Form.Label>パスワード</Form.Label>
												<Form.Control name="passwd" type="password" placeholder="パスワード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													「パスワード」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
											<Button className="btn-pink" type="submit" block size="lg" disabled={this.state.isLockForm} >ログイン</Button>
											<p className="pt-4"><Button variant="link" type="button" disabled={this.state.isLockForm} onClick={()=>{ this.setState({ isForgotPassword: this.state.isForgotPassword ? false : true });}}>※パスワードをお忘れですか？</Button></p>
											<p className="pt-4"><Button variant="link" type="button" disabled={this.state.isLockForm} onClick={()=>{ this.setState({ isPasswordResetRequired: this.state.isPasswordResetRequired ? false : true });}}>※検証コードの入力はこちらから</Button></p>
												<p className="red">※Internet Explorer11をご利用のお客様は、ログイン処理に時間がかかる場合がございます。少々お待ちください。また、Microsoft EdgeやGoogle Chromeなどのブラウザがおすすめです。</p>
											</Card.Body>
									</Card>
									</Form>
								</Col>
								<Col md={6} >
									<Form method="GET" action="/member/">
									<Card className="h-100">
										<Card.Body>
											<Card.Title>
												<span>初めての方・非会員の方</span>
											</Card.Title>
											<p>
												当店でのお買い物は会員登録なしでもお楽しみいただけますが、会員にご登録いただきますと、
												ご注文の際にお名前・ご住所などの入力が不要になり安全かつ簡単にご利用いただけます。<br />
												また、会員限定のセールなども今後行なっていく予定です。
											</p>
											<Button className="btn-blue" type="submit" block size="lg" disabled={this.state.isLockForm}>新規登録</Button>
										</Card.Body>
									</Card>
									</Form>
								</Col>
							</Row>
						</Container>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}

class ChangePassword extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
		}
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.lockFormClose = this.lockFormClose.bind(this);
	}
	async handleSubmit (event) {

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated : true,
			isLockForm  : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//親から直接取得する更新項目
			const email           = this.props.email;

			//Formから直接取得する更新項目
			const oldPassword     = formObj.oldPassword.value;
			const newPassword     = formObj.newPassword.value;

			//サインイン
			const result = await API.Auth.signIn(email, oldPassword);

			//requiredAttributesの取得
			const requiredAttributes = result.challengeParam.requiredAttributes;

			//パスワードの変更
			await API.Auth.completeNewPassword(result, newPassword, requiredAttributes);

		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.message){
				err_msg  = error.message + '<br/>';
			}
			else if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}
		//更新成功
		this.setState({
			isLockForm  : false,
		});
		this.props.reload();
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		return (
			<div>
				<Header/>
				<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss}>
					<Modal.Body>
						エラーがありました。<br/>
						{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={this.handleDismiss}>
							ＯＫ
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
					<Modal.Body className="text-center">
						<Image src="/img/loading.gif" />　
						データを更新中です。
					</Modal.Body>
				</Modal>

				<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>

				<main>
										<div className="head-logo sec">
											<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
										</div>
					<Container>
						<div className="pan d-flex justify-content-end">
							<div><a href="/">TOP</a></div>
							<div><a href="/">会員様ログイン</a></div>
						</div>
					</Container>
					<Container>
						<h1>
							LOGIN
							<p>
								会員様ログイン
							</p>
						</h1>
						<Row>
							<Col>
								<Card className="h-100">
									<Card.Body>
										<Card.Title>
											<span>パスワードの再登録が申請されたか、パスワードの有効期限が切れました。</span>
										</Card.Title>
										<p>新しいパスワードを設定してください。</p>
										<Form.Group controlId="oldPassword">
											<Form.Label>古いパスワード</Form.Label>
										        <Form.Control name="oldPassword" type="password" placeholder="古いパスワード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} autoComplete="off" />
											<Form.Control.Feedback type="invalid">
												「古いパスワード」を正しく入力してください。
											</Form.Control.Feedback>
										</Form.Group>

										<Form.Group controlId="newPassword">
											<Form.Label>新しいパスワード</Form.Label>
										        <Form.Control name="newPassword" type="password" placeholder="新しいパスワード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} autoComplete="off" />
											<Form.Control.Feedback type="invalid">
												「新しいパスワード」を正しく入力してください。
											</Form.Control.Feedback>
										</Form.Group>
										<Button className="btn-pink" type="submit" block size="lg" disabled={this.state.isLockForm} >設定</Button>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</main>
				</Form>
				<Footer/>
			</div>
		);
	}
}

class PasswordResetRequired extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : false,
			isValidated    : false,
			isLockForm     : false,
			isSuccess      : false,
			ErrMsg         : '',
		}
		this._reload       = this._reload.bind(this);
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.lockFormClose = this.lockFormClose.bind(this);
	}
	_reload() {
		this.setState({ isLoading: true });
		this.props.reload();
	}
	async handleSubmit (event) {

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated : true,
			isLockForm  : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const email    = formObj.email.value;
			const code     = formObj.code.value;
			const passwd   = formObj.passwd.value;

			//パスワード再設定
			await API.Auth.forgotPasswordSubmit(email, code, passwd);
		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.message){
				err_msg  = error.message + '<br/>';
			}
			else if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}
		//更新成功
		this.setState({
			isLockForm  : false,
			isSuccess   : true,
		});
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		return (

			<div>
				<Header/>
				<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss}>
					<Modal.Body>
						エラーがありました。<br/>
						{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={this.handleDismiss}>
							ＯＫ
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
					<Modal.Body className="text-center">
						<Image src="/img/loading.gif" />　
						データを更新中です。
					</Modal.Body>
				</Modal>
				<Modal show={this.state.isSuccess} onHide={this._reload}>
					<Modal.Body>
						パスワードを再登録しました。<br/>
						新しいパスワードで再度ログインを行ってください。
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={this._reload}>
							ＯＫ
						</Button>
					</Modal.Footer>
				</Modal>
				<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
				<main>
									<div className="head-logo sec">
										<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
									</div>
					<Container>
						<div className="pan d-flex justify-content-end">
							<div><a href="/">TOP</a></div>
							<div><a href="/">会員様ログイン</a></div>
						</div>
					</Container>
					<Container>
						<h1>
							LOGIN
							<p>
								会員様ログイン
							</p>
						</h1>
						<Row>
							<Col>
								<Card className="h-100">
									<Card.Body>
										<Card.Title>
											<span>パスワードの再登録が申請されました。</span>
										</Card.Title>
										<p>メールアドレスに届いた検証コードと新しいパスワードを入力してください。</p>
										<Form.Group controlId="email">
											<Form.Label>メールアドレス</Form.Label>
										        <Form.Control name="email" type="email" placeholder="メールアドレス" size="lg" defaultValue={this.props.email ? this.props.email : ''} required pattern=".{1,255}" disabled={this.state.isLockForm} autoComplete="off" />
											<Form.Control.Feedback type="invalid">
												「メールアドレス」を正しく入力してください。
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group controlId="code">
											<Form.Label>検証コード</Form.Label>
										        <Form.Control name="code" type="text" placeholder="検証コード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} autoComplete="off" />
											<Form.Control.Feedback type="invalid">
												「検証コード」を正しく入力してください。
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group controlId="passwd">
											<Form.Label>新しいパスワード</Form.Label>
										        <Form.Control name="passwd" type="password" placeholder="新しいパスワード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} autoComplete="off" />
											<Form.Control.Feedback type="invalid">
												「新しいパスワード」を正しく入力してください。
											</Form.Control.Feedback>
										</Form.Group>
										<Button className="btn-pink" type="submit" block size="lg" disabled={this.state.isLockForm} >登録</Button>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</main>
				</Form>
				<Footer/>
			</div>
		);
	}
}

class ForgotPassword extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : false,
			isValidated    : false,
			isLockForm     : false,
			isSuccess      : false,
			ErrMsg         : '',
		}
		this._reload       = this._reload.bind(this);
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.lockFormClose = this.lockFormClose.bind(this);
	}
	_reload() {
		this.setState({ isLoading: true });
		this.props.reload();
	}
	async handleSubmit (event) {

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated : true,
			isLockForm  : true,
		});

		//Formオブジェクトの確認
		const formObj     = event.currentTarget;
		if(!formObj){
			this.setState({
				ErrMsg     : '※不正なエラーが発生しました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Formオブジェクトの確認
		if(formObj.checkValidity() === false){
			this.setState({
				ErrMsg     : '※入力項目にエラーがありました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Formから直接取得する更新項目
		const email     = formObj.email.value;

		//更新処理
		try{
			//パスワード再設定
			await API.Auth.forgotPassword(email);
		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.message){
				err_msg  = error.message + '<br/>';
			}
			else if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}
		//更新成功
		this.setState({
			isLockForm  : false,
			isSuccess   : true,
			email       : email,
		});
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		if(this.state.isSuccess){
			return (
				<PasswordResetRequired email={this.state.email} reload={this._reload} />
			);
		}
		else{
			return (
				<div>
					<Header/>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss}>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
					<main>
											<div className="head-logo sec">
												<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
											</div>
						<Container>
							<div className="pan d-flex justify-content-end">
								<div><a href="/">TOP</a></div>
								<div><a href="/">会員様ログイン</a></div>
							</div>
						</Container>
						<Container>
							<h1>
								LOGIN
								<p>
									会員様ログイン
								</p>
							</h1>
							<Row>
								<Col>
									<Card className="h-100">
										<Card.Body>
											<Card.Title>
												<span>パスワードをお忘れですか？</span>
											</Card.Title>
											<p>パスワード再登録のための検証コードをお送りします。メールアドレスをご入力ください。</p>
											<Form.Group controlId="email">
												<Form.Label>メールアドレス</Form.Label>
												<Form.Control name="email" type="email" placeholder="Eメールアドレス" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													「メールアドレス」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
											<Button className="btn-pink" type="submit" block size="lg" disabled={this.state.isLockForm} >申請</Button>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>
					</main>
					</Form>
					<Footer/>
				</div>
			);
		}
	}
}

export class Profile extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isSuccess       : false,
			isSend          : true,
			email           : '',
			mg_kbn          : true,
			ErrMsg          : '',
		}
		this._getData             = this._getData.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSubmit         = this.handleSubmit.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData () {

		try{
			//Cognito IDの取得
			const user  = await API.Auth.currentAuthenticatedUser();
			const email = user.attributes.email;
			this.setState({
				isLoading  : false,
				email      : email,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async handleSubmit (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
				});
				return(0);
			}

			//Stateから直接取得する更新項目
			const isSend        = this.state.isSend;
			const email         = this.state.email;

			//Formオブジェクトの確認
			if(!email){
				this.setState({
					ErrMsg     : '※メールアドレスが取得出来ませんでした。<br/>',
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const name_1        = formObj.name_1.value;
			const name_2        = formObj.name_2.value;
			const name_furi_1   = formObj.name_furi_1.value;
			const name_furi_2   = formObj.name_furi_2.value;
			const zip_1         = formObj.zip_1.value;
			const zip_2         = formObj.zip_2.value;
			const pref          = formObj.pref.value;
			const addr_1        = formObj.addr_1.value;
			const addr_2        = formObj.addr_2.value;
			const addr_3        = formObj.addr_3.value;
			const tel_1         = formObj.tel_1.value;
			const tel_2         = formObj.tel_2.value;
			const tel_3         = formObj.tel_3.value;
			let   o_name_1      = formObj.o_name_1.value;
			let   o_name_2      = formObj.o_name_2.value;
			let   o_name_furi_1 = formObj.o_name_furi_1.value;
			let   o_name_furi_2 = formObj.o_name_furi_2.value;
			let   o_zip_1       = formObj.o_zip_1.value;
			let   o_zip_2       = formObj.o_zip_2.value;
			let   o_pref        = formObj.o_pref.value;
			let   o_addr_1      = formObj.o_addr_1.value;
			let   o_addr_2      = formObj.o_addr_2.value;
			let   o_addr_3      = formObj.o_addr_3.value;
			let   o_tel_1       = formObj.o_tel_1.value;
			let   o_tel_2       = formObj.o_tel_2.value;
			let   o_tel_3       = formObj.o_tel_3.value;
			const o_com_name    = formObj.o_com_name.value;
			const birth         = formObj.birth.value;
			const sex           = formObj.sex.value;
			const mg_kbn        = formObj.mg_kbn.value;

			const birth_ary = birth.split("-");
			const birth_y   = birth_ary[0];
			const birth_m   = birth_ary[1];
			const birth_d   = birth_ary[2];

			//発注者と同じ住所に送る場合
			if(isSend){
				o_name_1      = name_1      ;
				o_name_2      = name_2      ;
				o_name_furi_1 = name_furi_1 ;
				o_name_furi_2 = name_furi_2 ;
				o_zip_1       = zip_1       ;
				o_zip_2       = zip_2       ;
				o_pref        = pref        ;
				o_addr_1      = addr_1      ;
				o_addr_2      = addr_2      ;
				o_addr_3      = addr_3      ;
				o_tel_1       = tel_1       ;
				o_tel_2       = tel_2       ;
				o_tel_3       = tel_3       ;
			}

			//変更
			const refProfile = await API.Profile.Add(
						{
							email       : email         ,
							name_1      : name_1        ,
							name_2      : name_2        ,
							name_furi_1 : name_furi_1   ,
							name_furi_2 : name_furi_2   ,
							zip_1       : zip_1         ,
							zip_2       : zip_2         ,
							pref        : pref          ,
							addr_1      : addr_1        ,
							addr_2      : addr_2        ,
							addr_3      : addr_3 ? addr_3 : 'null',
							tel_1       : tel_1         ,
							tel_2       : tel_2         ,
							tel_3       : tel_3         ,
							birth_y     : birth_y       ,
							birth_m     : birth_m       ,
							birth_d     : birth_d       ,
							sex         : sex           ,
							mg_kbn_1    : mg_kbn        ,
						}
						);

			if(refProfile.error !== null){
				console.log(refProfile.error);
				//エラー処理
				this.setState({
					ErrMsg     : refProfile.error + '<br/>',
					isLockForm : false,
				 });
			}

			//変更
			const refAddress = await API.Address.Add(
							o_name_1     ,
							o_name_2     ,
							o_name_furi_1,
							o_name_furi_2,
							o_zip_1      ,
							o_zip_2      ,
							o_pref       ,
							o_addr_1     ,
							o_addr_2     ,
							o_addr_3 ? o_addr_3 : 'null'     ,
							o_tel_1      ,
							o_tel_2      ,
							o_tel_3      ,
							o_com_name ? o_com_name : 'null' ,
							true     ,
						);

			if(refAddress.error !== null){
				console.log(refAddress.error);
				//エラー処理
				this.setState({
					ErrMsg     : refAddress.error + '<br/>',
					isLockForm : false,
				 });
			}

			//メール送信
			const refa = await API.MailSend.Join('flag');
			console.log(refa);

			//更新成功
			this.setState({
				isSuccess  : true,
				isLockForm : false,
			});
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
			 });
			return(0);
		}
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
		});
	}
	handleClose(){
		//更新成功
		this.setState({
			isSuccess  : false,
		});
		this.props.reload();
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							更新しました。<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>

					<div className="head-logo sec">
						<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
					</div>

						<Container>
							<div className="pan d-flex justify-content-end">
								<div><a href="/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								REGISTRATION
								<p>
									新規会員登録
								</p>

							</h1>
							<h3><span>お客様情報を入力してください</span></h3>




							<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
							<Container className="mt-5">
								<Row>
									<Col md={12}>
										<Card>
											<Card.Body>
												<p>
													全て必須項目です。もれなくご入力ください。
												</p>
												<hr className="mb-4" />
												<Row>
													<Col md={6} mb={3}>
														<Form.Group controlId="name_1">
															<Form.Label>
																姓
															</Form.Label>
															<Form.Control type="text" name="name_1" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																名字を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col md={6} mb={3}>
														<Form.Group controlId="name_2">
															<Form.Label>
																名
															</Form.Label>
															<Form.Control type="text" name="name_2" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																名前を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<hr className="mb-4" />

												<Row>
													<Col md={6} mb={3}>
														<Form.Group controlId="name_furi_1">
															<Form.Label>
																セイ
															</Form.Label>
															<Form.Control type="text" name="name_furi_1" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																名字（カタカナ）を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col md={6} mb={3}>
														<Form.Group controlId="name_furi_2">
															<Form.Label>
																メイ
															</Form.Label>
															<Form.Control type="text" name="name_furi_2" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																名前（カタカナ）を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<hr className="mb-4" />
												<p className="small red">※ハイフン位置で区切って、郵便番号1（3桁）-郵便番号2（4桁）で入力してください。</p>
												<Row>
												<Col sm={3} xs={6}>
														<Form.Group controlId="zip_1">
															<Form.Label>
																郵便番号1(3桁)
															</Form.Label>
															<Form.Control type="text" name="zip_1" maxLength='3' size="lg" required pattern="\d{3}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																郵便番号1を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col sm={3} xs={6}>
														<Form.Group controlId="zip_2">
															<Form.Label>
																郵便番号2(4桁)
															</Form.Label>
															<Form.Control type="text" name="zip_2" maxLength='4' size="lg" required pattern="\d{4}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																郵便番号2を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<Row>
													<Col md={4} mb={3}>
														<Form.Group controlId="pref">
															<Form.Label>
																都道府県
															</Form.Label>
															<Form.Control type="text" name="pref" size="lg" required pattern=".{2,4}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																都道府県を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col md={8} mb={3}>
														<Form.Group controlId="addr_1">
															<Form.Label>
																市町村
															</Form.Label>
															<Form.Control type="text" name="addr_1" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																市町村を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<Row>
														<Col  sm={6}>
														<Form.Group controlId="addr_2">
															<Form.Label>番地</Form.Label>
															<Form.Control type="text" name="addr_2" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																住所を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
														<Col  sm={6}>
														<Form.Group controlId="addr_3">
															<Form.Label>
																アパート・マンション名
															</Form.Label>
															<Form.Control type="text" name="addr_3" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
														</Form.Group>
													</Col>
												</Row>
												<hr className="mb-4" />
												<p className="form-label">電話番号</p>
	<p className="small red">※ハイフン位置で3つに区切って入力してください。（例）090　1234　5678</p>
												<Row>
														<Col>
														<Form.Group controlId="tel_1">
															<Form.Control type="text" name="tel_1" maxLength='4' size="lg" required pattern="\d{2,4}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																電話番号を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
														<Col>
														<Form.Group controlId="tel_2">
															<Form.Control type="text" name="tel_2" maxLength='4' size="lg" required pattern="\d{2,4}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																電話番号を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
														<Col>
														<Form.Group controlId="tel_3">
															<Form.Control type="text" name="tel_3" maxLength='4' size="lg" required pattern="\d{2,4}" disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																電話番号を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<hr className="mb-4"/>
												<Row>
													<Col md={6}>
														<Form.Label>
															生年月日
														</Form.Label>
													</Col>
													<Col md={6}>
														<Form.Group controlId="birth">
															<Form.Control type="date" name="birth" size="lg" defaultValue={'1980-01-01'} required disabled={this.state.isLockForm} />
															<Form.Control.Feedback type="invalid">
																誕生日を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<hr className="mb-4"/>

												<Form.Group controlId="sex">
												<Row>
													<Col md={3}>
														<Form.Label>
															性別
														</Form.Label>
													</Col>
													<Col md={2}>
														<Form.Check type="radio" name="sex" id="sex_1" defaultValue="男性" label="男性" required disabled={this.state.isLockForm} />
													</Col>
													<Col md={2}>
														<Form.Check type="radio" name="sex" id="sex_2" defaultValue="女性" label="女性" required disabled={this.state.isLockForm} />
													</Col>
													<Col md={5}>
														<Form.Control.Feedback type="invalid">
															性別を選択してください
														</Form.Control.Feedback>
													</Col>
												</Row>
												</Form.Group>

												<hr className="mb-4"/>

												<Row>
													<Col md={3}>
														<Form.Label>メルマガ購読</Form.Label>
													</Col>
													<Col md={2}>
														<Form.Check type="radio" name="mg_kbn" id="mg_kbn_1" defaultValue={true} label="はい" defaultChecked={this.state.mg_kbn ? true : false} required disabled={this.state.isLockForm} />
													</Col>
													<Col md={2}>
														<Form.Check type="radio" name="mg_kbn" id="mg_kbn_2" defaultValue={false} label="いいえ" defaultChecked={this.state.mg_kbn ? false : true } required disabled={this.state.isLockForm} />
													</Col>
												</Row>
												<hr className="mb-4"/>

												<Row>
													<Col md={3}>
														<Form.Label>お届け先</Form.Label>
													</Col>
													<Col md={3}>
														<Form.Check type="radio" name="isSend" id="is_send_1" label="上記の住所へ送る" disabled={this.state.isLockForm} onClick={()=>{ this.setState({ isSend : true }); }} defaultChecked={this.state.isSend} />
													</Col>
													<Col md={3}>
														<Form.Check type="radio" name="isSend" id="is_send_2" label="別の住所へ送る" disabled={this.state.isLockForm} onClick={()=>{ this.setState({ isSend : false }); }} defaultChecked={!this.state.isSend} />
													</Col>
												</Row>
												<hr className="mb-4"/>

												<div><h2>お届け先の登録</h2></div>
												<Row>
													<Col md={6} mb={3}>
														<Form.Group controlId="o_name_1">
															<Form.Label>
																姓
															</Form.Label>
															<Form.Control type="text" name="o_name_1" size="lg" required={!this.state.isSend} pattern=".{1,255}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																名字を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col md={6} mb={3}>
														<Form.Group controlId="o_name_2">
															<Form.Label>
																名
															</Form.Label>
															<Form.Control type="text" name="o_name_2" size="lg" required={!this.state.isSend} pattern=".{1,255}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																名前を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<hr className="mb-4"/>

												<Row>
													<Col md={6} mb={3}>
														<Form.Group controlId="o_name_furi_1">
															<Form.Label>
																セイ
															</Form.Label>
															<Form.Control type="text" name="o_name_furi_1" size="lg" required={!this.state.isSend} pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																名字（カタカナ）を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col md={6} mb={3}>
														<Form.Group controlId="o_name_furi_2">
															<Form.Label>
																メイ
															</Form.Label>
															<Form.Control type="text" name="o_name_furi_2" size="lg" required={!this.state.isSend} pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																名前（カタカナ）を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<hr className="mb-4"/>
												<p className="small red">※ハイフン位置で区切って、郵便番号1（3桁）-郵便番号2（4桁）で入力してください。</p>
												<Row>
													<Col sm={3} xs={6}>
														<Form.Group controlId="o_zip_1">
															<Form.Label>
																郵便番号1(3桁)
															</Form.Label>
															<Form.Control type="text" name="o_zip_1" size="lg" required={!this.state.isSend} pattern="\d{3}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																郵便番号1を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col sm={3} xs={6}>
														<Form.Group controlId="o_zip_2">
															<Form.Label>
																郵便番号2(4桁)
															</Form.Label>
															<Form.Control type="text" name="o_zip_2" size="lg" required={!this.state.isSend} pattern="\d{4}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																郵便番号2を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<Row>
													<Col md={4} mb={3}>
														<Form.Group controlId="o_pref">
															<Form.Label>
																都道府県
															</Form.Label>
															<Form.Control type="text" name="o_pref" size="lg" placeholder="" required={!this.state.isSend} pattern=".{2,4}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																都道府県を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col md={8} mb={3}>
														<Form.Group controlId="o_addr_1">
															<Form.Label>
																市町村
															</Form.Label>
															<Form.Control type="text" name="o_addr_1" size="lg" placeholder="" required={!this.state.isSend} pattern=".{1,255}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																市町村を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<Row>
														<Col sm={6}>
														<Form.Group controlId="o_addr_2">
															<Form.Label>
																番地
															</Form.Label>
															<Form.Control type="text" name="o_addr_2" size="lg" placeholder="" required={!this.state.isSend} pattern=".{1,255}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																住所を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>

													<Col sm={6}>
														<Form.Group controlId="o_addr_3">
															<Form.Label>
																アパート・マンション名
															</Form.Label>
															<Form.Control type="text" name="o_addr_3" size="lg" placeholder="" pattern=".{0,255}" disabled={this.state.isLockForm || this.state.isSend} />
														</Form.Group>
													</Col>
												</Row>
												<hr className="mb-4"/><p className="form-label">電話番号</p>
	<p className="small red">※ハイフン位置で3つに区切って入力してください。（例）090　1234　5678</p>
												<Row>
													<Col>
														<Form.Group controlId="o_tel_1">
															<Form.Control type="text" name="o_tel_1" size="lg" placeholder="" required={!this.state.isSend} pattern="\d{2,4}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																電話番号を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group controlId="o_tel_2">
															<Form.Control type="text" name="o_tel_2" size="lg" placeholder="" required={!this.state.isSend} pattern="\d{2,4}}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																電話番号を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group controlId="o_tel_3">
															<Form.Control type="text" name="o_tel_3" size="lg" placeholder="" required={!this.state.isSend} pattern="\d{2,4}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																電話番号を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
												<hr className="mb-4"/>
												<p className="small red">※必要な場合は入力してください。</p>
												<Row>
													<Col mb={3}>
														<Form.Group controlId="o_com_name">
															<Form.Label>
																会社名
															</Form.Label>
															<Form.Control type="text" name="o_com_name" size="lg" placeholder="" pattern=".{0,255}" disabled={this.state.isLockForm || this.state.isSend} />
															<Form.Control.Feedback type="invalid">
																会社名を入力してください
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>

												<hr className="mb-4"/>

												<Button size="lg" className="btn-blue" type="submit" block disabled={this.state.isLockForm} >
													登録する
												</Button>

											</Card.Body>
										</Card>

									</Col>
								</Row>
							</Container>
							</Form>
						</Container>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}
