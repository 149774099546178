import React from 'react';
import { Auth } from 'aws-amplify';
import { API } from '../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
//	Badge,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from './@Include/NotFound';
import Header from './@Include/Header';
import Footer from './@Include/Footer';
//import ReactPlayer from 'react-player';
//import CosmeSlick from './CosmeSlick.js';
//import CosmeColmun from './CosmeColmun';
//import CosmeInfo from './CosmeInfo';

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			IdentityId     : '',
			cognitoid      : '',
		}
		this.handleReload         = this.handleReload.bind(this);
		this._getData             = this._getData.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//Cognito IDの取得
			const currentCredentials = await Auth.currentCredentials();
			const IdentityId         = currentCredentials.data.IdentityId;

			//Cognito IDの取得
			const user         = await API.Auth.currentAuthenticatedUser();
			const cognitoid    = user.username;

			const ref = await API.Profile.Get();
			console.log(ref);

			this.setState({
				isLoading     : false,
				IdentityId    : IdentityId,
				cognitoid     : cognitoid,
			});
		}
		catch(error) {
			this.setState({
				isLoading     : false,
			});
			return(0);
		}
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 3000, marginBottom: 3000 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<div>
				<Header/>
					<main>

						<div className="head-logo sec">
							<a className="" href="/"><Image src="../svg/logo_header_cosme.svg" /></a>
						</div>

<Container className="mb-5 text-center"><a href="/category/フルプルコスメ-フルプルバスソルト/" className="opa06"><Image src="/img/slider/bnr_bathsalt2109.jpg" fluid /></a></Container>
{/*<CosmeSlick />
<Container className="my-5 ">
	<div className="oshirase">
	<h3>GWの休業に伴う商品発送のお知らせ</h3>
	<p>マインドウェイブオンラインストアでは誠に勝手ながら、下記期間をゴールデンウィークの休業とさせていただきます。<br />
ご不便をおかけいたしますが、ご理解賜りますようよろしくお願い申し上げます。</p>

<p><strong className="red">■休業期間 5月2日（土）〜 5月6日（水）<br />
■出荷開始日 5月7日（木）より順次</strong></p>
<ul>
<li>※5月1日（金）午前9：00までのご注文は1日中の出荷を予定しておりますが、ご注文が殺到しておりますため、休業明けの発送となる場合がございます。</li>
<li>※ご注文につきましては当該期間中も承っておりますが、商品の発送・お問い合わせの返信等は、翌営業日より順に対応させていただきます。</li>
<li>※お届け日のご指定をいただきましてもご希望にそえない場合がございます。あらかじめご了承くださいませ。</li>
</ul>
	</div>
</Container>

 <Container className="mt-5">
	 <a href="/category/フルプルコスメ-フルプルクリーム/" className="opa06">
	<picture>
 <source media="(max-width: 767px)" srcSet="/img/202005/bnr_boxpre-nyan_sp.gif" />
		 <Image src="/img/202005/bnr_boxpre-nyan.gif" alt="フルプルクリーム2個ご購入で1つプレゼント" fluid />
	 </picture>
 </a>
 </Container>

<Container className="mt-5 pt-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202210/bnr_nenga2023_sp.jpg" />
 <Image src="/img/202210/bnr_nenga2023.jpg" alt="キャンペーン" fluid />
</picture>
</Container>
<Container className="mt-3 pt-5">
	<a href="/search/ウィンターセール/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="https://www.mindwave-store.com/img/202212/bnr_sale2212_sp.jpg" />
	 <Image src="https://www.mindwave-store.com/img/202212/bnr_sale2212.jpg" alt="SALE" fluid/>
 </picture></a>
</Container>
<Container className="mt-5 pt-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202301/bnr_free2301_sp.jpg" />
 <Image src="/img/202301/bnr_free2301.jpg" alt="送料無料キャンペーン" fluid className="bdrimg" />
</picture>
</Container>
<Container className="mt-5 pt-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202308/bnr_free2308_sp.jpg" />
 <Image src="/img/202308/bnr_free2308.jpg" alt="送料無料キャンペーン" fluid className="bdrimg" />
</picture>
</Container>*/}

<Container className="mt-3">
 <picture>
 <source media="(max-width: 767px)" srcSet="/img/top/bnr_sticker_sp.jpg" />
 	<Image src="/img/top/bnr_sticker.jpg" alt="書き下ろしステッカープレゼント！" fluid />
 </picture>
 </Container>
<Container className="mt-3">
<a href="/guide/#wrapping" className="opa06">
			 <picture>
			<source media="(max-width: 767px)" srcSet="/img/guide/img_card_sp.jpg" />
				 <Image src="/img/guide/img_card.jpg" alt="ラッピング" className="bdrimg" fluid/>
			 </picture>
</a>
</Container>
 
 {/* <Container className="mt-5">
			 <picture>
			<source media="(max-width: 767px)" srcSet="/img/guide/img_wrapping-rb_sp.png" />
				 <Image src="/img/guide/img_wrapping-rb.png" alt="季節限定ラッピング" fluid/>
			 </picture>
</Container>

<Container className="mb-5">
	<div className="oshirase text-center">
	<h3 className="red"><strong><i className="fas fa-truck"></i> 大雪や荒天の影響によるお荷物のお届けについて</strong></h3>
	<p>大雪や荒天の影響により、一部地域においてお荷物の 集荷・配達業務見合わせ・遅れが生じております。<br />
詳しくは配送業者サイトにてご確認いただきますようお願いいたします。<br />
お客様にはご不便をおかけいたしますが、何卒ご理解のほど、よろしくお願い申し上げます。</p>
<p><strong className="">
<a href="https://www.kuronekoyamato.co.jp/ytc/chien/chien_hp.html"><i className="fas fa-caret-right"></i> ヤマト運輸</a> ／ <a href="https://www.post.japanpost.jp/notification/productinformation/2021/0113_01.html"><i className="fas fa-caret-right"></i> 日本郵政</a>
</strong></p>
	</div>
</Container>
*/}
{/* ここに休業案内 */}
<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="https://www.mindwave-store.com/img/info/info_holidays_sp.jpg" />
	 <Image src="https://www.mindwave-store.com/img/info/info_holidays.jpg" alt="休業のご案内" fluid />
</picture>
</Container>
{/* 
 <Container className="mb-5">
	 <h2>LINE UP<p>コスメ ラインナップ</p></h2>
        <Row>
       <Col sm={6} className="mt-3 mb-3 text-center">
					<a href="/others/furupurucosme"><Image src="/img/cosme/top_furu.jpg" className="topimg"/></a>
					<p className="top_catelogo"><Image src="/img/cosme/cosmettl_furu.svg" style={{ width: 320}}  className="mt-4 mb-3" fluid /></p>
						<ul className="cosme-sns">
							<li><a href="https://twitter.com/furupuru_cosme"><i className="fab fa-twitter-square fa-lg"></i><span> Twitter</span></a></li>
							<li><a href="https://www.instagram.com/furupuru_cosme/"><i className="fab fa-instagram fa-lg"></i><span> Instagram</span></a></li>
						</ul>
			</Col>
       <Col sm={6} className="mt-3 text-center"><a href="/search/贅沢美肌堂/"><Image src="/img/cosme/top_zeitaku202003.jpg" className="topimg" /></a>
<p className="top_catelogo"><Image src="/img/cosme/cosmettl_zeitaku.svg" fluid style={{ width: 205}} /></p>
			 <ul className="cosme-sns color_zeitakubihada">
				 <li><a href="https://twitter.com/zeitakubihadado"><i className="fab fa-twitter-square fa-lg"></i><span> Twitter</span></a></li>
				 <li><a href="https://www.instagram.com/zeitakubihadado/"><i className="fab fa-instagram fa-lg"></i><span> Instagram</span></a></li>
			 </ul></Col>
      </Row>
    </Container>
*/}
<Container>
						<h2>NEW ITEM<p>新商品</p></h2>
<Row className="top_setitem mt-3">
<Col xs={4} sm={3} md={2}>
	<a href="/item/95273/"><Image src="https://images.mindwave-store.com/public/95273_main.jpg" />
				<h5 className="list-title">フルプル バスソルト 95273 しばんばんの湯 さくら 2</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/95274/"><Image src="https://images.mindwave-store.com/public/95274_main.jpg" />
				<h5 className="list-title">フルプル バスソルト 95274 しばんばんの湯 ゆず 2</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/95275/"><Image src="https://images.mindwave-store.com/public/95275_main.jpg" />
				<h5 className="list-title">フルプル バスソルト 95275 しばんばんの湯 りんご 2</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/95276/"><Image src="https://images.mindwave-store.com/public/95276_main.jpg" />
				<h5 className="list-title">フルプル バスソルト 95276 しばんばんの湯 ラベンダー2</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/95277/"><Image src="https://images.mindwave-store.com/public/95277_main.jpg" />
				<h5 className="list-title">フルプル バスソルト 95277 しばんばんの湯 はちみつレモン</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/95278/"><Image src="https://images.mindwave-store.com/public/95278_main.jpg" />
				<h5 className="list-title">フルプル バスソルト 95278 しばんばんの湯 ピーチ 2</h5></a></Col>
</Row>
 </Container>

			{/*
  <Container className="my-5">
	<a href="/search/【12月25日までの期間限定】/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202011/bnr_gift2011_sp.jpg" />
	 <Image src="/img/202011/bnr_gift2011.jpg" alt="SALE" fluid />
 </picture></a>
 </Container>*/}
 

 <Container fluid={true} className="bg_gray pt-5 pb-5 ">
						<h2>GIFT SET<p>新ギフトセットが登場！<br />
							本サイトだけ10%OFFでお得!!CUTEで使える贈り物を★</p></h2>
 <Container>
<hr />
 <Row>
 <picture>
<source media="(max-width: 767px)" srcSet="/img/cosme/cosme_gift03_sp.png" />
		<Image src="/img/cosme/cosme_gift03.png" alt="コスメ専用サイトオリジナルのギフトセットが10%OFF！" fluid />
	</picture>
</Row>
<hr />
 </Container>
  <Container>
<Row className="top_setitem mt-5">
{/* <Col xs={12} md={6}><a href="/item/CA210208/"><Image src="/img/set/bnr_CA210208.jpg" className="pb-3" /><h5 className="list-title text-center">にゃんすけとフルプルクリームセット</h5></a></Col>*/}
<Col xs={12} md={4}><a href="/item/CA230303/"><Image src="/img/set/bnr_CA230303.jpg" className="pb-3" /><h5 className="list-title text-center">くろしばんばん 癒しのぬいぐるみ＆バスソルトセット</h5></a></Col>

<Col xs={12} md={4}><a href="/item/CA230901/"><Image src="/img/set/bnr_CA230901.jpg" className="pb-3" /><h5 className="list-title text-center">バスソルト6種の湯めぐりセット B</h5></a></Col>
{/* 
<Col xs={12} md={6}><a href="/item/CA210202/"><Image src="/img/set/bnr_CA210202.jpg" className="pb-3" /><h5 className="list-title text-center">3DAYSしばんばんフェイスマスクセット</h5></a></Col>
<Col xs={12} md={6}><a href="/item/CA210203/"><Image src="/img/set/bnr_CA210203.jpg" className="pb-3" /><h5 className="list-title text-center"> 6DAYS集中!フェイスマスクセット</h5></a></Col>
<Col xs={6} md={4}><a href="/item/CA210205/"><Image src="/img/set/bnr_CA210205.jpg" className="pb-3" /><h5 className="list-title text-center">しばんばん保湿＋マスキングテープセット</h5></a></Col>

<Col xs={6} md={6}><a href="/item/CA210209/"><Image src="/img/set/bnr_CA210209.jpg" className="pb-3" /><h5 className="list-title text-center">にゃんすけ 癒しのマスコット&フルプルセット</h5></a></Col>
<Col xs={6} md={4}><a href="/item/CA210204/"><Image src="/img/set/bnr_CA210204.jpg" className="pb-3" /><h5 className="list-title text-center">にゃんすけ保湿＋マスキングテープセット</h5></a></Col>

<Col xs={6} md={6}><a href="/item/CA210206/"><Image src="/img/set/bnr_CA210206.jpg" className="pb-3" /><h5 className="list-title text-center">KIGURU・ME保湿＋マスキングテープセット</h5></a></Col>*/}
<Col xs={12} md={4}><a href="/item/CA211001/"><Image src="/img/set/bnr_CA211001.jpg" className="pb-3" /><h5 className="list-title text-center">バスソルト6種の湯めぐりセット A</h5></a></Col>

</Row>
{/*
<Col xs={4} sm={3} md={2}><a href="/item/CA191003/"><Image src="/img/cosme/gift35.jpg" /><h5 className="list-title">3DAYSしばんばんフェイスマスクセット</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/CA191009/"><Image src="/img/cosme/gift34.jpg" /><h5 className="list-title">6DAYS集中！フェイスマスクセット</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/CA190406/"><Image src="/img/cosme/gift06.jpg" /><h5 className="list-title">バスソルト6種の湯めぐりセット</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/CA191007/"><Image src="/img/cosme/gift05.jpg" /><h5 className="list-title">しばんばんしっかり保湿コンプリートセット</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/CA191008/"><Image src="/img/cosme/gift33.jpg" /><h5 className="list-title">にゃんすけしっかり保湿コンプリートセット</h5></a></Col>
<Col xs={4} sm={3} md={2}><a href="/item/CA191002/"><Image src="/img/cosme/gift32.jpg" /><h5 className="list-title">デリシャススターズ保湿セット</h5></a></Col>
</Row>

       <Row className="pt-5">
			 				<Col xs={12} sm={4} className="mb-3"><a href="/item/CM190501/" className="opa06"><Image src="/img/cosme/gift_190501.jpg" fluid rounded alt="にゃんすけ香りの魔法コロンギフト" /></a></Col>
 							<Col xs={12} sm={4} className="mb-3"><a href="/item/CM190601/" className="opa06"><Image src="/img/cosme/gift_190601.jpg" fluid rounded alt="にゃんすけの手ぬぐい＆フルプルクリームセット" /></a></Col>
 							<Col xs={12} sm={4} className="mb-3"><a href="/item/CM190701/" className="opa06"><Image src="/img/cosme/gift_190701.jpg" fluid rounded alt="しばんばん 癒しのぬいぐるみ&バスソルトセット" /></a></Col>
       </Row>
 */}
 </Container>
 </Container>

<Container>
						<h2>RECOMMEND ITEM<p>おすすめ商品</p></h2>
<Row className="top_setitem mt-3">

{/* 
<Col xs={6} sm={3}><a href="/item/CM210203/"><Image src="https://images.mindwave-store.com/public/CM210203_main.jpg"  className="mb-3 bdrimg" /><h5 className="list-title">ギフトセット 【抹茶deにゃんすけ】</h5></a></Col><Col xs={6} sm={3}><a href="/item/94451/"><Image src="/img/cosme/gift08.jpg" className="pb-3"  /><h5 className="list-title">ハンドクリーム＆ミニタオルセット 94451 しばんばん</h5></a>
<p className="list-price">￥1,320<span>（税込）</span></p></Col>
<Col xs={6} sm={3}><a href="/item/94453/"><Image src="/img/cosme/gift10.jpg" className="pb-3" /><h5 className="list-title">ハンドクリーム＆ミニタオルセット 94453 ごろごろにゃんすけ</h5></a>
<p className="list-price">￥1,320<span>（税込）</span></p></Col>
<Col xs={4} sm={4}><a href="/item/94454/"><Image src="/img/cosme/gift11.jpg" className="mb-3 bdrimg" /><h5 className="list-title">ハンドクリーム＆ミニタオルセット 94454 デリシャス スターズ</h5></a></Col>*/}
<Col xs={6} sm={6}><a href="/search/フルプル%20フェイスマスク/"><Image src="/img/202008/recommend_mask.jpg"  className="mb-3 bdrimg" alt="" /></a></Col>
<Col xs={6} sm={6}><a href="/category/フルプルコスメ-フルプルバスソルト/"><Image src="/img/top/recommend_salt.jpg" className="mb-3 bdrimg" alt="フルプルバスソルト" /></a></Col>
{/* <Col xs={12} sm={6}><a href="/search/OUTLET/"><Image src="/img/202008/outletsale_l.jpg" className="pb-3" alt="OUTLET SALE" /></a></Col>*/}
</Row>
 </Container>
 {/* <Container className="mb-5">
	 <picture>
	 <source media="(max-width: 767px)" srcSet="/img/202009/img_wrapping-bk_sp.png" />
		 <Image src="/img/202009/img_wrapping-bk.png" alt="季節限定ラッピング" fluid/>
	 </picture>
	 </Container>
*/}
 <Container fluid={true} className="bg_gray mt-5 pt-5 pb-5 ">
              <Container className="mt-3 mb-5">
   <h2>RANKING<p>ランキング</p></h2>
								        <Row>

<div className="category-wrap">
<div className="ranking-box">
		<a href="/item/CA231027/">
<p className="ranking-img item-img"><Image src="https://images.mindwave-store.com/public/CA231027_main.jpg" /></p>
	<h5 className="cate-title">【送料込み】バスソルト12種の湯めぐり 36袋セット【19%OFF】</h5></a>
</div>
<div className="ranking-box">
          <a href="/item/CA230303/">
<p className="ranking-img item-img"><Image src="https://images.mindwave-store.com/public/CA230303_main.jpg" /></p>
        <h5 className="cate-title">くろしばんばん 癒しのぬいぐるみ&バスソルトセット【10%OFF】</h5></a>
</div>
<div className="ranking-box">
	<a href="/item/CA230901/">
<p className="ranking-img item-img"><Image src="https://images.mindwave-store.com/public/CA230901_main.jpg" /></p>
<h5 className="cate-title">バスソルト6種の湯めぐりセット B【10%OFF】</h5></a>
</div>


</div>
</Row>
    </Container>
    </Container>

		{/*<CosmeColmun />*/}

<Container className="mt-5 mb-5">
		<h2>INFORMATION<p>おしらせ</p></h2>
		<dl className="topinfo">
		<dt><i className="fas fa-info-circle"></i> 2024.01.05　令和6年能登半島地震の影響による配送不可・遅延のお知らせ</dt>
	<dd>この度の令和6年能登半島地震により被害に遭われました皆様に、心よりお見舞いを申し上げるとともに、一日も早い復旧を心よりお祈り申し上げます。<br />この地震の影響により、一部地域においてお荷物の 集荷・配達業務見合わせ・遅れが生じております。<br />
		詳しくは配送業者サイトにてご確認いただきますようお願いいたします。<br />
お客様にはご不便をおかけいたしますが、何卒ご理解のほど、よろしくお願い申し上げます。
<p><a href="https://www.yamato-hd.co.jp/important/" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> ヤマト運輸</a> ／ <a href="https://www.post.japanpost.jp/newsrelease/index.html" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> 日本郵政</a>
</p></dd>
		
		<dt><i className="fas fa-info-circle"></i> 2023.04.21　宅急便の一部区間における「お届け日数」と「指定時間帯」について</dt>
	<dd>ヤマト運輸の宅急便の配送にかかる日数について、2023年6月1日（木）から下記区間でお荷物のお届け日数と指定時間帯が変更となり、最短でのお届けが出荷日の翌々日午前中以降となります。<br />詳しくは【<a href="https://www.yamato-hd.co.jp/important/info_230417_1.html" target="_blank" rel='noopener noreferrer'>ヤマト運輸 <i class="fas fa-external-link-alt"></i></a> 】へご確認ください。<br />
【対象エリア】東京都、神奈川県、千葉県、埼玉県、茨城県、群馬県、栃木県、山梨県、新潟県</dd>
		{/*
		<dt><i className="fas fa-info-circle"></i> 2023.12.22　天候不良・年末年始の影響によるお荷物のお届けの遅延について</dt>
	<dd>天候不良・年末年始の影響により、一部地域においてお荷物の 集荷・配達業務見合わせ・遅れが生じております。<br />
		詳しくは配送業者サイトにてご確認いただきますようお願いいたします。<br />
お客様にはご不便をおかけいたしますが、何卒ご理解のほど、よろしくお願い申し上げます。
<p><a href="https://www.yamato-hd.co.jp/important/" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> ヤマト運輸</a> ／ <a href="https://www.post.japanpost.jp/newsrelease/index.html" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> 日本郵政</a>
</p></dd>
<dt><i className="fas fa-info-circle"></i> 2022.10.11　後払い決済手数料の変更について</dt>
	<dd>GMOペイメントサービス株式会社の手数料改定に伴い、当店の後払い決済手数料が10月11日9：00より236円へ変更となりました。<br />誠に恐れ入りますが、何卒ご理解のほど、よろしくお願い申し上げます。</dd>
		<dt><i className="fas fa-info-circle"></i> 2021.09.01　明細書同封廃止のお知らせ</dt>
	<dd>2021年9月11日0:00以降のご注文分より、明細書の同封を廃止させていただきます。<br />当店では、環境への配慮、お客様の個人情報保護の取り組みのため、今まで商品に同梱しておりました『お買上げ明細書』のペーパーレス化を実施させていただくこととなりました。つきましては、当店からお送りいたします受注確定メールにて、ご注文内容をご確認くださいますようお願い申し上げます。<br />
	お買上げ明細書をご希望の方は、PDFデータにてお送りいたしますので、お手数ですが、ご注文の際の備考欄にご記載くださいませ。<br />
会員のお客様は、マイページからもご注文履歴をご確認いただけます。</dd>*/}
		<dt><i className="fas fa-info-circle"></i> ご注文前にメール設定をご確認ください</dt>
	<dd>ご注文前に、必ず「＠mindwave-store.com」のドメインからのメールを受信できるよう、ご設定のご確認をお願いいたします。<br />gmail、hotmail、yahooメールなど、フリーのメールをご利用の場合は「迷惑メール」フォルダに自動的に入る場合がございます。また、携帯電話のメールアドレスの場合、なりすましメール拒否等のセキュリティ設定で受信できないことがございますので、ドメイン指定受信設定をお願いいたします。</dd>
	<dt><i className="fas fa-info-circle"></i> 明細書は同梱しておりません</dt>
	<dd>当店では、環境への配慮、お客様の個人情報保護の取り組みのため、『お買上げ明細書』は同梱しておりません。つきましては、当店からお送りいたします受注確定メールにて、ご注文内容をご確認くださいますようお願い申し上げます。<br />
	お買上げ明細書をご希望の方は、PDFデータにてお送りいたしますので、お手数ですが、ご注文の際の備考欄にご記載くださいませ。<br />
会員のお客様は、マイページからもご注文履歴をご確認いただけます。</dd>	
		</dl>
<Row>
	
	{/*<CosmeInfo />*/}
{/*<dt>2019.11.08　台風19号の影響によるお荷物のお届けについて</dt>
	<dd>11月に入りだいぶ回復しているようではございますが、台風19号の影響により、一部地域の配達に未だ遅延が発生しております。配送の詳しい状況は、
		「<a href="https://www.post.japanpost.jp/notification/productinformation/index.html" target="_blank" rel="noopener noreferrer">日本郵便</a>」
		「<a href="http://www.kuronekoyamato.co.jp/ytc/info/info_2019.html" target="_blank" rel="noopener noreferrer">ヤマト運輸</a>」のWebサイトよりご確認ください。</dd>*/}
<Col xs={12} sm={12} md={6} className="mb-4"><a href="https://www.mindwave-store.com/stationary_index/" className="opa06"><Image src="/img/top/bnr_stationery0601.png" alt="文具ストアTOP" fluid thumbnail /></a>
</Col>
<Col xs={12} sm={12} md={6} className="mb-4">
<a href="https://images.mindwave-store.com/mz/ncolumn/column_top.html" className="opa06"><Image src="/img/top/bnr_column.png" alt="オンラインストアコラム" fluid thumbnail /></a>
</Col>
<Col xs={12} sm={12} md={6} className="mb-4">
<a href="/member/" className={this.state.cognitoid ? 'opa06 membersnone' : 'opa06'}><Image src="/img/top/bnr_mailmagazine.png" alt="メルマガ登録はこちら" fluid thumbnail /></a></Col>

</Row>
</Container>


  </main>
					<Footer/>
						</div>
			);
		}
	}
}

export default withRouter(Default);
