import React from 'react';
import { withRouter } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';

class Default extends React.Component {
	componentDidMount () {
		const url = '^https://cosme.mindwave-store.com/';
		const regex = new RegExp(url);
		if(document.referrer.match(regex) === null){
			window.location.href = '/';
		}
		else{
			this.props.history.goBack();
		}
	}
	render() {
		return (
			<div/>
		);
	}
}

export default withAuthenticator(withRouter(Default));
