import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
	Card,
} from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isValidated     : false,
			isLockForm      : false,
			isSuccess       : false,
			isShowUnSub     : false,
			isDoui          : false,
			formObj         : '',
			ErrMsg          : '',
		}
		this._getData             = this._getData.bind(this);
		this._douiClick           = this._douiClick.bind(this);
		this.unSubSubmit          = this.unSubSubmit.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSubmit         = this.handleSubmit.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData() {

		//ログインの確認
		try{

			this.setState({
				isLoading  : false,
			 });
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			//読み込み完了
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg + '<br/>',
			 });
			return(0);
		}

	}
	_douiClick(e) {
		if(e.target.checked === true){
			this.setState({ isDoui: true });
		}
		else{
			this.setState({ isDoui: false });
		}
	}
	async unSubSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowUnSub  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//カート変更
			const ref = await API.Profile.UnSub('DisableUser');

			if(ref.error !== null){
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}

			//成功
			this.setState({
				isSuccess  : true,
				isLockForm : false,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isDeleted   : true,
			isLockForm  : false,
		});
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading       : true,
		});
		this._getData();
	}
	async handleSubmit(event){
		event.preventDefault();
		event.stopPropagation();
		const formObj = event.currentTarget;

		if(!formObj){
			this.setState({
				ErrMsg     : '※不正なエラーが発生しました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Formオブジェクトの確認
		if(formObj.checkValidity() === false){
			this.setState({
				ErrMsg     : '※入力項目にエラーがありました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//stateから直接取得する更新項目
		const isDoui  = this.state.isDoui;

		if(isDoui === false){
			this.setState({
				ErrMsg     : '※退会規約に同意してください。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//退会メール
		await API.MailSend.Resign('flag');

		this.setState({
			isShowUnSub : true,
			formObj     : formObj,
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose(){
		this.setState({
			isShowUnSub  : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg       : '',
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else if(this.state.isSuccess){
			return (
				<Redirect to="/logout/"/>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isShowUnSub} onHide={this.handleClose} centered>
						<Modal.Body>本当に退会しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.unSubSubmit} type="button" className="btn btn-blue">
								はい
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/">
								<Image src="/svg/logo_header_cosme.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
								<div>会員退会</div>
							</div>
						</Container>




						<Container>
							<h1>MY PAGE<p>マイページ</p></h1>
							<h2>会員退会</h2>
						</Container>

						<Container>
							<p>
								次の「退会規約」の内容をお読みいただき、「退会約款に同意する」にチェックして次へ進んでください。
							</p>
							<p>
								※退会手続きを実行された場合は、退会規約にご同意いただいたものと見なします。あらかじめご了承下さい。
							</p>
							<Row>
								<Col sm={12}>
									<div className="rule-frame">
										<h4>退会規約</h4>
										<p>
											『マインドウェイブ オンラインストア』の会員で、退会を希望する場合は以下の｢退会規約｣を必ずお読み下さい。<br/>
											退会手続き実行された方は、以下の規約にご同意いただいたものとみなします。
										</p>
										<div className="rule">
											<dl>
												<dt>
													退会手続きについて
												</dt>
												<dd>
													ご利用者が退会を希望する場合には、退会手続きページから弊社の指定する方法に従い退会申請を行うものとします。
												</dd>
												<dt>
													退会にあたっての注意事項
												</dt>
												<dd>
													退会ご希望の方は、以下の事項にご注意下さい。
													<ul>
														<li>ご利用者が退会手続きを行った場合、注文履歴、アドレス帳、お気に入り商品等の「マイページ」で閲覧できた情報は閲覧できなくなります。</li>
														<li>退会後、同じID（メールアドレス）で再登録しても退会前の情報が復元することはありません。</li>
													</ul>
												</dd>
												<dt>
													登録済みの個人情報について
												</dt>
												<dd>
													ご利用者が退会手続きを行った場合、弊社はご利用者の個人情報の利用を速やかに停止します。
													しかし、未発送商品がある場合やご注文に関する連絡事項が発生した場合は、
													弊社は退会後も一定期間内は利用者の情報を保存する場合があります。
												</dd>

												<dt>
													商品未着時の退会について
												</dt>
												<dd>
													ご利用者が退会手続きを行った場合でも、注文済みで未発送の商品がある場合は、会員未登録でのご注文と同様に商品配送を行い、出荷通知等のご連絡を行います。
												</dd>
											</dl>
										</div>
									</div>
								</Col>
							</Row>
						</Container>

						<Form noValidate validated={this.state.isValidated} onSubmit={this.handleSubmit}>
						<Container>
							<Row>
								<Col className="offset-sm-1 col-sm-10 offset-md-2 col-md-8 mt-5">
									<Card>
										<Card.Body>
											<Form.Check inline name="doui" type="checkbox" id="inline-checkbox-1" label="退会規約に同意する" onClick={this._douiClick} disabled={this.state.isLockForm} />

											<Button type="submit" className="btn-bk" size="lg" block disabled={this.state.isLockForm}>退会手続きを実行する</Button>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>
						</Form>

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withAuthenticator(withRouter(Default));
