import Amplify, { Auth } from 'aws-amplify';
import AwsExports from './include/AwsExports';
import { Cart } from './Objects/CartCosme';
import { Profile } from './Objects/Profile';
import { Address } from './Objects/Address';
import { Item } from './Objects/Item';
import { Category } from './Objects/Category';
import { PreOrder } from './Objects/PreOrder';
import { Order } from './Objects/Order';
import { Setting } from './Objects/Setting';
import { RecentlyViewed } from './Objects/RecentlyViewedCosme';
import { WishList } from './Objects/WishListCosme';
import { SquareCard } from './Objects/SquareCard';
import { StripeCard } from './Objects/StripeCard';
import { Coupon } from './Objects/Coupon';
import { MailSend } from './Objects/MailSend';

Amplify.configure(AwsExports);


const API = {
	Auth     : Auth,
	Cart     : Cart,
	Profile  : Profile,
	Address  : Address,
	Item     : Item,
	Category : Category,
	PreOrder : PreOrder,
	Order    : Order,
	Setting  : Setting,
	RecentlyViewed : RecentlyViewed,
	WishList : WishList,
	SquareCard : SquareCard,
	StripeCard : StripeCard,
	Coupon   : Coupon,
	MailSend : MailSend,
};


export { API };
