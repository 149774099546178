import React from 'react';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';

class OtherZeitakubihadado extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
{/*			<Container>
				<div className="head-logo sec">
					<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
				</div>

					<div className="pan">
						<div><a href="/">TOP</a></div>
						<div>贅沢美肌堂（抹茶de美肌）</div>

					</div>
</Container>*/}

<Container fluid={true} className="bg_zeneral">
	<Container>
	    <Row>
				<picture>
				<source media="(max-width: 700px)" srcSet="/img/others/maccha/maccha_one_sp.jpg" />
				<Image src="/img/others/maccha/maccha_one_pc.jpg" alt="フェイスパック部門で3つの1位を獲得しました！" fluid />
			</picture>
				    </Row>
</Container>
	</Container>

	<Container>
	    <Row>
	    <Col sm={4} className="obon"  >
	<Image src="/img/others/maccha/head_txt.png" alt="自然の力で、もっと輝ける自分に。" fluid />
<p className="text-center text-lg pt-5">日本古来の植物成分にこだわり、<br />
肌への優れたアプローチを<br />追求する化粧品ブランド。<br />
「贅沢美肌堂」<br />
澄みきった、透明感のある美しい肌へ。</p>
	</Col>
	<Col sm={8}>
<Image src="/img/others/maccha/maccha_head.jpg" fluid />
	</Col>
	    </Row>
</Container>

<Container>
	<h3><span>お使いいただいた方の声</span></h3>
	<Row>
<Col sm={6} md={3}><p className="voice">流すとお肌の手触りがすべすべ。お風呂上がりにはしっとり柔らか！</p></Col>
<Col sm={6} md={3}><p className="voice">肌がワントーン明るくなった気がします。翌日の化粧ノリがいいです</p></Col>
<Col sm={6} md={3}><p className="voice">柔らかめのテクスチャーだけど垂れないし、伸びが良くて使いやすいのがいい</p></Col>
<Col sm={6} md={3}><p className="voice">グリーンのパック姿が子供達に好評で 、お風呂タイムに楽しくケアできました</p></Col>
	</Row>
	<p className="text-sm text-right">※個人の方の使用後の感想です</p>
</Container>


<Container fluid={true} className="maccha_bg">
<Container>
  <Row>
<Col xs={12} sm={6} className="macchatxt"><Image src="/img/others/maccha/txt_maccha.png" alt="抹茶はチアシードやキヌアに並ぶ日本のスーパーフード"  /></Col>
<Col xs={12} sm={6} className="pt-5">世界の健康・美容意識の高い人々からは「matcha」として英語表記でも認識されており、チアシードやキヌア等と並び、スーパーフードとして非常に高い関心を集めています。</Col>
<Col sm={12} className="pt-5"><picture>
<source media="(max-width: 600px)" srcSet="/img/others/maccha/img_maccha_sp.png" />
<Image src="/img/others/maccha/img_maccha_pc.png" alt="抹茶に含まれる栄養素" fluid /></picture>
</Col>
  </Row>
</Container>
</Container>

<Container className="pt-5 pb-5">
  <Row>
<Col xs={12} sm={6} className="maccha-catch pb-3"><strong>「抹茶de美肌」では人気の高い国産茶葉ブランド<span className="green">「京都宇治抹茶」を使用</span>し、
	厳正な管理のもと栽培されている、<span className="green">100％オーガニック茶葉</span>にこだわりました。</strong></Col>
<Col xs={6} sm={3}><Image src="/img/others/maccha/img_maccha02.jpg" fluid  /></Col>
<Col xs={6} sm={3}><Image src="/img/others/maccha/img_maccha03.jpg" fluid  /></Col>
  </Row>
</Container>

<Container className="moreline">
<Row>
<Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 1 }} className="align-self-center ">
<Image src="/img/others/maccha/img_more.jpg" alt="" fluid  />
</Col>
<Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 2 }} className="align-self-center">
<h2 className="maccha">くすみを取りのぞき、透明感のある肌へ</h2>
<p>抹茶パウダーがメラニンを含む古い角質をやさしく落とし、保湿成分の緑茶エキス・ヒアルロン酸Naが肌をやわらかく潤してくれます。</p>
<h2 className="maccha">昼のダメージ、夜にケアして美肌キープ</h2>
<p>紫外線や乾燥など昼間に受けたダメージは、夜に遅れて肌に現れます。<br />
その日のうちにケアして、ダメージを翌日に持ち越さないのが重要です。</p>
</Col>
</Row>
</Container>

<Container>
	<picture>
	<source media="(max-width: 600px)" srcSet="/img/others/maccha/maccha_winter02_sp.jpg" />
	<Image src="/img/others/maccha/maccha_winter02.jpg" alt="洗い流すフェイスパックでしっとりやわらかい肌へ。" fluid /></picture>
</Container>


<Container className="pt-5 pb-5" >
		<h3><span>シートマスクとの違いは？</span></h3>
<div class="card-deck">
  <div class="card">
    <div class="card-body">
      <h4 className="green">保湿と同時に角質ケア</h4>
      <p class="card-text">シートマスクは保湿がメイン。抹茶de美肌はスクラブ成分（オーガニック抹茶パウダー）入りで角質ケアも同時にできます。</p>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h4 className="green">衣類が汚れない</h4>
      <p class="card-text">シートマスクは液垂れで衣服が汚れることもありますが、バスタイムでも使用できる抹茶de美肌なら心配なし！</p>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h4 className="green">血流アップ</h4>
      <p class="card-text">抹茶de美肌は入浴しながら使用することで血流が良くなり、体の内側からも外側からも同時にケアできます。</p>
    </div>
  </div>
	  <div class="card">
	    <div class="card-body">
	      <h4 className="green">リラックス効果</h4>
	      <p class="card-text">入浴時に使用することで「抹茶ミルクの香り」がより引き立ち、ほんのり甘い香りにリラックス効果もアップ！</p>
	    </div>
	  </div>
</div>
</Container>



<Container fluid={true} className="mt-5 bathtime">
			<Row>
	<Container>
		<Row>
	<picture>
	<source media="(max-width: 600px)" srcSet="/img/others/maccha/img_bathtime_sp.jpg" />
	<Image src="/img/others/maccha/img_bathtime02_pc.jpg" alt="フェイスパックでかんたんバスタイムのフェイスケアはいかがですか" fluid /></picture>
	</Row>
</Container>
</Row>



</Container>



<Container className="howto-content">
	<h3><span>ご使用方法</span></h3>
<picture>
<source media="(max-width: 600px)" srcSet="/img/others/maccha/howto_sp.jpg" />
<Image src="/img/others/maccha/howto_pc.jpg" alt="ご使用方法" fluid />
 </picture>
</Container>

<Container className="maccha_footer">
		<Row>
<Col xs={{ span: 12, order: 1 }} sm={{ span: 4, order: 1 }} className="footeritem text-center">
<Image src="/img/others/maccha/img_product.jpg" alt="抹茶de美肌" />
</Col>
<Col xs={{ span: 12, order: 2 }} sm={{ span: 8, order: 2 }}>
	<picture>
	<source media="(max-width: 600px)" srcSet="/img/others/maccha/footer_txt_sp.png" />
	<Image src="/img/others/maccha/footer_txt_pc.png" alt="" fluid /></picture>
<p className="mt-5 text-center"><a href="/item/94329/" className="btn btn-lg macchabtn"><i className="fa fa-shopping-cart"></i> ご購入はこちらから</a></p>
</Col>
</Row>
<div className="text-center"><a href="/item/94841/" className="opa06 link_pack"><Image src="/img/others/maccha/link_mask.png" /></a></div>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OtherZeitakubihadado);
