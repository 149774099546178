import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			ErrMsg          : '',
			orderData       : [],
		}
		this._getData             = this._getData.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData () {

		try{

			//アドレスデータを取得
			const ref   = await API.Order.GetAll();

			//データ更新
			this.setState({
				orderData   : ref.items ? ref.items : [],
				isLoading   : false,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
		});
	}
	handleClose(){
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/">
								<Image src="/svg/logo_header_cosme.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								MY PAGE
								<p>
									マイページ
								</p>
							</h1>
</Container>
							<Container fluid>
								<Row>
									<ul className="sub-menu">
										<li><a href="/mypage/profile/">ご登録内容の変更</a></li>
										<li><a href="/mypage/wishlist/">お気に入り商品</a></li>
										<li><a href="/mypage/address/">お届け先アドレス帳</a></li>
										<li><a href="/cart/">カート</a></li>
										<li><a href="/mypage/order/">過去のご注文</a></li>
										<li><a href="/mypage/unsub/">会員退会</a></li>
									</ul>
								</Row>
							</Container>

							<Form>
							<Container>
								<h2>過去のご注文</h2>
								<Row>
									<Col className="offset-md-1 col-md-10">
										<table className="order-view table table-hover">
											<thead>
											<tr>
											<th scope="col">ご注文日</th>
											<th scope="col">受注番号</th>
											<th scope="col">お届け先</th>
											<th scope="col">発送状況</th>
											<th scope="col">発送日</th>
											<th scope="col">伝票番号</th>
											<th scope="col">詳細</th>
											</tr>
											</thead>
											<tbody>
											{this.state.orderData.map( item =>{

												const date_add      = item.date_add;
												const orderno       = item.orderno;
												const o_name_1      = item.o_name_1;
												const o_name_2      = item.o_name_2;
												const status        = item.status;
												const delivery_no   = item.delivery_no === 'null' ? '' : item.delivery_no;
												const delivery_date = item.delivery_date === 'null' ? '' : item.delivery_date;

												const dateObj     = new Date(date_add);
												const year        = dateObj.getFullYear().toString();
												const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
												const day         = ('00' + dateObj.getDate().toString()).slice(-2);
								//				const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
								//				const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
								//				const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

												return(
													<tr key={orderno}>
														<td data-label="ご注文日">{year}年{month}月{day}日&nbsp;</td>
														<td data-label="受注番号">{orderno}&nbsp;</td>
														<td data-label="お届け先">{o_name_1} {o_name_2} 様&nbsp;</td>
														<td data-label="発送状況">{status}&nbsp;</td>
														<td data-label="発送日">{delivery_date}&nbsp;</td>
														<td data-label="伝票番号">{delivery_no}&nbsp;</td>
														<td data-label="詳細">
															<Button type="button" className="btn-blue" onClick={()=>{ this.props.history.push('/mypage/order/' + orderno + '/'); }}>
																詳細
															</Button>
														</td>
													</tr>
												);
											})}
											</tbody>
										</table>
									</Col>
								</Row>
							</Container>
							</Form>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withAuthenticator(withRouter(Default));
