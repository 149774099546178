import { API, graphqlOperation } from 'aws-amplify';

const Address = {};

Address.Get = async function (addressid)  {
		
	try{
		//パラメータ確認
		if(!addressid){ return({ error : "システムIDがありません。" }); }

		const Query = `query getAddress($addressid: String!) {
			getAddress(addressid: $addressid) {
				o_name_1
				o_name_2
				o_name_furi_1
				o_name_furi_2
				o_zip_1
				o_zip_2
				o_pref
				o_addr_1
				o_addr_2
				o_addr_3
				o_tel_1
				o_tel_2
				o_tel_3
				o_com_name
				use_flag
				date_add
				date_edit

			}
		}`;
		const Data = { addressid : addressid };
		const ref = await API.graphql(graphqlOperation(Query, Data));
		
		const item = ref.data.getAddress;
		
		const o_name_1      = item.o_name_1      ;
		const o_name_2      = item.o_name_2      ;
		const o_name_furi_1 = item.o_name_furi_1 ;
		const o_name_furi_2 = item.o_name_furi_2 ;
		const o_zip_1       = item.o_zip_1       ;
		const o_zip_2       = item.o_zip_2       ;
		const o_pref        = item.o_pref        ;
		const o_addr_1      = item.o_addr_1      ;
		const o_addr_2      = item.o_addr_2      ;
		const o_addr_3      = item.o_addr_3      ;
		const o_tel_1       = item.o_tel_1       ;
		const o_tel_2       = item.o_tel_2       ;
		const o_tel_3       = item.o_tel_3       ;
		const o_com_name    = item.o_com_name    ;
		const use_flag      = item.use_flag      ;
		const date_add      = item.date_add      ;
		const date_edit     = item.date_edit     ;
		
		if(date_add){
			return ({
				item: {
					addressid     : addressid     ,
					o_name_1      : o_name_1      ,
					o_name_2      : o_name_2      ,
					o_name_furi_1 : o_name_furi_1 ,
					o_name_furi_2 : o_name_furi_2 ,
					o_zip_1       : o_zip_1       ,
					o_zip_2       : o_zip_2       ,
					o_pref        : o_pref        ,
					o_addr_1      : o_addr_1      ,
					o_addr_2      : o_addr_2      ,
					o_addr_3      : o_addr_3 === 'null' ? '' : item.o_addr_3,
					o_tel_1       : o_tel_1       ,
					o_tel_2       : o_tel_2       ,
					o_tel_3       : o_tel_3       ,
					o_com_name    : o_com_name === 'null' ? '' : item.o_com_name,
					use_flag      : use_flag      ,
					date_add      : date_add      ,
					date_edit     : date_edit     ,
				},
				error: null,
			});
		}
		else{
			return ({
				item: null,
				error: 'アドレスが見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			item: null,
			error: error,
		});
	}
}

Address.GetAll = async function ()  {
		
	try{
		const Query = `query getAddressAll {
			getAddressAll {
				items{
					addressid
					o_name_1
					o_name_2
					o_name_furi_1
					o_name_furi_2
					o_zip_1
					o_zip_2
					o_pref
					o_addr_1
					o_addr_2
					o_addr_3
					o_tel_1
					o_tel_2
					o_tel_3
					o_com_name
					use_flag
					date_add
					date_edit
				}
			}
		}`;
		const ref = await API.graphql(graphqlOperation(Query));
		const items = ref.data.getAddressAll.items;

		const newData = [];

		for(const item of items){
				newData.push({
					addressid     : item.addressid     ,
					o_name_1      : item.o_name_1      ,
					o_name_2      : item.o_name_2      ,
					o_name_furi_1 : item.o_name_furi_1 ,
					o_name_furi_2 : item.o_name_furi_2 ,
					o_zip_1       : item.o_zip_1       ,
					o_zip_2       : item.o_zip_2       ,
					o_pref        : item.o_pref        ,
					o_addr_1      : item.o_addr_1      ,
					o_addr_2      : item.o_addr_2      ,
					o_addr_3      : item.o_addr_3 === 'null' ? '' : item.o_addr_3,
					o_tel_1       : item.o_tel_1       ,
					o_tel_2       : item.o_tel_2       ,
					o_tel_3       : item.o_tel_3       ,
					o_com_name    : item.o_com_name === 'null' ? '' : item.o_com_name,
					use_flag      : item.use_flag      ,
					date_add      : item.date_add      ,
					date_edit     : item.date_edit     ,
				});
		}
		
		if(newData.length !== 0){
			return ({
				items: newData,
				error: null,
			});
		}
		else{
			return ({
				items: null,
				error: 'アドレスが見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

Address.Add = async function (o_name_1,o_name_2,o_name_furi_1,o_name_furi_2,o_zip_1,o_zip_2,o_pref,o_addr_1,o_addr_2,o_addr_3,o_tel_1,o_tel_2,o_tel_3,o_com_name,use_flag)  {

	try{

		//新規
		const Query = `mutation addAddress($data: AddressInput!) {
			addAddress(input: $data) {
				addressid
			}
		}`;
		const Data = {
			data : {
				o_name_1      : o_name_1       ,
				o_name_2      : o_name_2       ,
				o_name_furi_1 : o_name_furi_1  ,
				o_name_furi_2 : o_name_furi_2  ,
				o_zip_1       : o_zip_1        ,
				o_zip_2       : o_zip_2        ,
				o_pref        : o_pref         ,
				o_addr_1      : o_addr_1       ,
				o_addr_2      : o_addr_2       ,
				o_addr_3      : o_addr_3 ? o_addr_3 : 'null',
				o_tel_1       : o_tel_1        ,
				o_tel_2       : o_tel_2        ,
				o_tel_3       : o_tel_3        ,
				o_com_name    : o_com_name ? o_com_name : 'null',
				use_flag      : use_flag ? true : false,
			}
		};
		//console.log(Data);
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const item = ref.data.addAddress;
		
		const addressid = item.addressid;

		return ({
			item  :{
				addressid : addressid,
			},
			error : null
		});
	}
	catch(error) {
		return({ error: error });
	}
}

Address.Update = async function (addressid,o_name_1,o_name_2,o_name_furi_1,o_name_furi_2,o_zip_1,o_zip_2,o_pref,o_addr_1,o_addr_2,o_addr_3,o_tel_1,o_tel_2,o_tel_3,o_com_name,use_flag)  {

	try{
		//パラメータ確認
		if(!addressid){ return({ error : "システムIDがありません。" }); }

		//更新
		const Query = `mutation updateAddress($data: AddressInput!) {
			updateAddress(input: $data) {
				addressid
			}
		}`;
		const Data = {
			data : {
				addressid     : addressid       ,
				o_name_1      : o_name_1       ,
				o_name_2      : o_name_2       ,
				o_name_furi_1 : o_name_furi_1  ,
				o_name_furi_2 : o_name_furi_2  ,
				o_zip_1       : o_zip_1        ,
				o_zip_2       : o_zip_2        ,
				o_pref        : o_pref         ,
				o_addr_1      : o_addr_1       ,
				o_addr_2      : o_addr_2       ,
				o_addr_3      : o_addr_3 ? o_addr_3 : 'null',
				o_tel_1       : o_tel_1        ,
				o_tel_2       : o_tel_2        ,
				o_tel_3       : o_tel_3        ,
				o_com_name    : o_com_name ? o_com_name : 'null',
				use_flag      : use_flag ? true : false,
			}
		};
		await API.graphql(graphqlOperation(Query, Data));

		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

Address.Remove = async function (addressid)  {

	try{
		//パラメータ確認
		if(!addressid){ return({ error : "システムIDがありません。" }); }

		//削除
		const Query = `mutation removeAddress($addressid: String!) {
			removeAddress(addressid: $addressid) {
				addressid
			}
		}`;
		const Data = { addressid: addressid };
		
		await API.graphql(graphqlOperation(Query, Data));
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

export { Address };
