import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
	Card,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isValidated     : false,
			isLockForm      : false,
			ErrMsg          : '',
			wishlistData    : [],

		}
		this._getData             = this._getData.bind(this);
		this.cartAdd              = this.cartAdd.bind(this);
		this.wishlistRemove       = this.wishlistRemove.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData() {

		try{
			//会員データを取得
			const refWishList = await API.WishList.Get();

			//表示更新
			this.setState({
				isLoading     : false,
				wishlistData  : refWishList.items,
			});

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm : false,
			 });
		}
	}
	async cartAdd (code) {

		//更新処理
		try{

			//カート追加
			const ref = await API.Cart.Add(code, 1);

			if(ref.error === null){
				//カートに移動
				this.props.history.push('/cart/');
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async wishlistRemove (code) {

		//更新処理
		try{
			//カート追加
			const ref = await API.WishList.Remove(code);
			console.log(ref);
			if(ref.error === null){
				this._getData();
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading       : true,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose(){
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/">
								<Image src="/svg/logo_header_cosme.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								MY PAGE
								<p>
									マイページ
								</p>
							</h1>
	</Container>
							<Container fluid>
								<Row>
									<ul className="sub-menu">
										<li><a href="/mypage/profile/">ご登録内容の変更</a></li>
										<li><a href="/mypage/wishlist/">お気に入り商品</a></li>
										<li><a href="/mypage/address/">お届け先アドレス帳</a></li>
										<li><a href="/cart/">カート</a></li>
										<li><a href="/mypage/order/">過去のご注文</a></li>
										<li><a href="/mypage/unsub/">会員退会</a></li>
									</ul>
								</Row>
							</Container>

							<Container>
								<h2>
									お気に入り商品
								</h2>
								<Row>
									<div className="wishlist">
									{
										this.state.wishlistData.map( item =>{

											 const code       = item.code;
											 const name       = item.name;

											return(
												<Card key={code}>
													<Card.Body>
														<a href={'/item/' + code + '/'}>
															<Image className="item-img mx-auto d-block" src={'https://images.mindwave-store.com/public/' + code + '_main.jpg'} />
														</a>
														<h5 className="list-title">
															<a href={'/item/' + code + '/'}>
																{name}
															</a>
														</h5>
														<div className="row-btn">
															<Form>
																<Button type="button" className="btn-pink" block onClick={()=>{ this.cartAdd(code) }}>
																	カート
																</Button>
																<Button type="button" className="btn-bk" block onClick={()=>{ this.wishlistRemove(code) }}>
																	削除
																</Button>
															</Form>
														</div>
													</Card.Body>
												</Card>
											);
										})
									}
									</div>
								</Row>
							</Container>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withAuthenticator(withRouter(Default));
