import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
	Card,
} from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isValidated     : false,
			isLockForm      : false,
			isRedirect      : false,
			ErrMsg          : '',
		}
		this._getData             = this._getData.bind(this);
		this._passwdChange        = this._passwdChange.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSubmit         = this.handleSubmit.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData() {

		try{

			//表示更新
			this.setState({
				isLoading      : false,
			});

		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm : false,
			 });
		}
	}
	async _passwdChange(old_passwd, new_passwd){

		try {
			const user = await API.Auth.currentAuthenticatedUser();
			await API.Auth.changePassword(user, old_passwd, new_passwd);
			return(null);
		}
		catch(error) {
			return(error.message);
		}

	}
	async handleSubmit (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const old_passwd = formObj.old_passwd.value;
			const new_passwd = formObj.new_passwd.value;

			//コードの検証
			const err = await this._passwdChange(old_passwd, new_passwd);
			if(err){
				this.setState({
					ErrMsg     : err + '<br/>',
					isLockForm : false,
				});
				return(0);
			}
			else{
				//成功
				this.setState({
					isSuccess     : true,
					isLockForm    : false,
				 });
			}
		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			isNextPage  : false,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose(){
		this.setState({
			isRedirect    : true,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isRedirect){
			return (
				<Redirect to="/mypage/"/>
			);
		}
		else if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							更新しました。<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/">
								<Image src="/svg/logo_header_cosme.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								MY PAGE
								<p>
									マイページ
								</p>
							</h1>
	</Container>
							<Container fluid>
								<Row>
									<ul className="sub-menu">
										<li><a href="/mypage/profile/">ご登録内容の変更</a></li>
										<li><a href="/mypage/wishlist/">お気に入り商品</a></li>
										<li><a href="/mypage/address/">お届け先アドレス帳</a></li>
										<li><a href="/cart/">カート</a></li>
										<li><a href="/mypage/order/">過去のご注文</a></li>
										<li><a href="/mypage/unsub/">会員退会</a></li>
									</ul>
								</Row>
							</Container>

							<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
							<Container>
								<h2>ご登録内容の変更</h2>
								<h3><span>変更する項目にご入力ください</span></h3>
								<Row>
									<Col className="offset-md-1 col-md-10">
									<Card>
										<Card.Body>
											<Row>
												<Col mb={3}>
													<Form.Group controlId="old_passwd">
														<Form.Label>
															古いパスワード
														</Form.Label>
														<Form.Control type="password" name="old_passwd" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															古いパスワードを入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col mb={3}>
													<Form.Group controlId="new_passwd">
														<Form.Label>
															新しいパスワード
														</Form.Label>
														<Form.Control type="password" name="new_passwd" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															新しいパスワードを入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4" />
										<Row>
										<Col>
											<Button size="lg" type="submit" className="btn-blue btn" block  disabled={this.state.isLockForm} >
												変更
											</Button>
											</Col>
											<Col>
											<Button size="lg" type="reset" className="btn-bk btn" block onClick={()=>{ this.props.history.push('/mypage/'); }}  disabled={this.state.isLockForm} >
												戻る
											</Button>
										</Col>
										</Row>
										</Card.Body>
									</Card>
									</Col>
								</Row>
							</Container>
							</Form>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withAuthenticator(withRouter(Default));
