import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isValidated    : false,
			isLockForm     : false,
			isNextPage     : false,
			ErrMsg         : '',
		}
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.handleReload     = this.handleReload.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
	}
	async handleSubmit(event){
		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const name     = formObj.name.value;
			const tel      = formObj.tel.value;
			const email    = formObj.email.value;
			const message  = formObj.message.value;

			//メール送信
			const refa = await API.MailSend.Contact('flag', email, name, tel, message);
			console.log(refa);

			this.setState({
				isNextPage : true,
				isLockForm : false,
			 });

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	render() {
		if(this.state.isNextPage){
			return (
								<div>
									<Header/>
				<main>
				<div className="head-logo sec"><a className="" href="/"><img src="/svg/logo_header_cosme.svg" alt="logo" /></a></div>
				<div className="container">
				<div className="pan">
				<div><a href="../">TOP</a></div>
				<div><a href="index.html">お問い合わせ</a></div>
				</div>
				</div>
				<div className="container">
				<h1>CONTACT<p>お問い合わせ</p></h1>
				<h2><img src="/img/step_contact03.svg" className="img-fluid te mx-auto d-block" alt="送信完了" /></h2>
				<h3><span>お問い合わせを送信しました</span></h3>
				<p>この度は、お問合せいただき誠にありがとうございました。</p>
				<p>お送りいただきました内容を確認の上、担当者より折り返しご連絡させていただきます。<br />
				また、ご記入いただきましたメールアドレスへ、自動返信の確認メールを送付しています。自動返信メールが届かない場合は、迷惑メールボックスやメールアドレスをご確認の上、再度一度フォームよりお問合せ頂きますようお願い申し上げます。
				</p>
				<p className="text-lg red">お急ぎの場合はお電話にてご連絡くださいませ。（TEL：06-7220-4222）</p>
				<div className="card mt-5">
				<h4 className="card-title"><span>各通知メールの不着について</span></h4>
				<div className="card-body">
				<p>プロバイダーやウィルス対策ソフトの迷惑メール対策機能により、当サイトからのメールが迷惑メールと混同され、除外されている可能性もあります。
				お問い合わせの前に、一度迷惑フォルダ等をご確認下さい。</p>
				<p>特に、hotmail・Yahooメール・Gmailなどのフリーメールや携帯メールドレスへのメールが届かない場合が多くございます。
				お客様のパソコンのセキュリティソフトや、メールソフトの受信設定などが影響している場合がございますので、「迷惑メールフォルダ」や受信設定を一度ご確認いただけますと幸いです。
				</p>
				</div>
				</div>
				<div className="mt-5"><a href="/" className="btn btn-blue btn-lg btn-block">TOPページへ</a></div>
				</div>
				</main>
				<fotoer/>
				</div>
			);

		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
						<main>
						<div className="head-logo sec"><a className="" href="/"><img src="/svg/logo_header_cosme.svg" alt="logo" /></a></div>

						<div className="container">
						<div className="pan">
						<div><a href="../">TOP</a></div>
						<div><a href="regist.html">お問い合わせ</a></div>
						</div>

						<h1>CONTACT<p>お問い合わせ</p></h1>

						<h2>お問い合わせの前に</h2>

						<div className="row toi_faq mb-3">
						<div className="col-sm-8"><p className="mb-3">サービスに関するよくある質問をご紹介しております。ぜひこちらもご覧ください。</p></div>
						<div className="col-sm-4"><a href="/guide/faq/" className="btn btn-lg btn-block btn-mw">よくあるご質問</a></div>
						</div>

						</div>
						<div className="container mt-5">
												<div className="toi_cate">
												<div className="toi_parts">
												<h4>店頭でマインドウェイブの<br/>商品をご購入のお客様へ</h4>
												<p>大変申し訳ございませんが、当サイト以外の販売店などでご購入された商品につきましては、当サイトでは対応できかねます。
												ご購入された販売店へお問い合わせください。</p>
												</div>
												<div className="toi_parts">
												<h4>取材依頼や卸販売等に<br/>ついてのお問い合わせ</h4>
												<p>マインドウェイブ社へ直接お問い合わせください。</p>
												<a href="https://www.mindwave.co.jp/contact/" className="btn btn-lg btn-toi btn-bk btn-block"><img src="../svg/btn_mw.svg" alt="マインドウェイブ社お問い合わせフォーム"/></a>
												</div>
												<div className="toi_parts">
												<h4>商品仕様や取り扱い店舗に<br/>ついてのお問い合わせ</h4>
												<p>マインドウェイブ社へ直接お問い合わせください。</p>
												<a href="https://www.mindwave.co.jp/contact/" className="btn btn-lg btn-bk btn-toi btn-block"><img src="../svg/btn_mw.svg" alt="マインドウェイブ社お問い合わせフォーム"/></a>
												</div>
												</div>
						</div>

						<div className="container">
						<h2>お問い合わせフォーム</h2>
						<p><img src="../img/step_contact01.svg" className="img-fluid te mx-auto d-block" alt="入力"/></p>

						<h3><span>必要事項をご記入ください</span></h3>
						</div>

						<div className="container">
						<div className="row">
						<div className="offset-md-1 col-md-10">
						<p>ご質問等に関しては、確認後、担当者よりご連絡させていただきますので、メールアドレスや、お電話番号は間違いのないようご確認ください。項目は全てご入力ください。</p>
						<p>送信前にこちらの「<a href="/company/policy/">個人情報の取り扱いについて</a>」を必ずお読みください。</p>

						<div className="card">
						<div className="card-body">

						<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>

						<div className="row">
						<div className="col-md-3">
						<label htmlFor="familyName">お名前</label></div>
						<div className="col-md-9 mb-3">
						<input type="text" className="form-control form-control-lg" id="name" name="name" required placeholder=""/>
						<div className="invalid-feedback">
						お名前を入力してください
						</div>
						</div>
						</div>
						<hr className="mb-4"/>

						<div className="row">
						<div className="col-md-3"><label htmlFor="exampleInputEmail1">メールアドレス</label></div>
						<div className="col-md-9 mb-3">
						<input type="email" className="form-control form-control-lg" name="email" required id="exampleInputEmail1" placeholder=""/>
						<div className="invalid-feedback">
						メールアドレスを入力してください
						</div>
						</div>
						</div>
						<hr className="mb-4"/>

						<div className="row">
						<div className="col-md-3"><label htmlFor="tel">電話番号</label></div>
						<div className="col-md-9 mb-3">
						<input type="tel" className="form-control form-control-lg" id="tel" name="tel" required placeholder=""/>
						<div className="invalid-feedback">
						電話番号を入力してください
						</div>
						</div>
						</div>
						<hr className="mb-4"/>

						<div className="row">
						<div className="col-md-3"><label htmlFor="tel">お問い合わせ内容</label></div>
						<div className="col-md-9 mb-3">
						<p>お近くの販売店などをお問い合わせの場合は、ご住所（町名までで結構です）も入力してください。</p>
						<textarea id="toiawase" rows="5" className="form-control form-control-lg" name="message" required placeholder=""></textarea>
						<div className="invalid-feedback">
						お問い合わせ内容を入力してください
						</div>
						</div>
						</div>

						<p> <Button type="submit" className="btn btn-blue btn-block btn-lg">送信</Button></p>

						</Form>
						</div>
						</div>
						</div>
						</div>
						</div>

						</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withRouter(Default);
