import React from 'react';
import {
	Container,
	Image,
} from 'react-bootstrap';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';

class NotFound extends React.Component {
	render() {
		return (
			<div>
			<Header/>
					<main>
							<Container className="pt-5 pb-5 text-center">
						<div className=" head-logo sec"><a href="/"><Image src="/svg/logo_header_cosme.svg" /></a></div>
							<h1>404 Not Found</h1>
							<h2>お探しのページは見つかりませんでした。</h2>
<p>アクセスしていただいたこのページは、削除されたかURLが変更されているため、見つけることができませんでした。</p>
<p>ホームページのリニューアルに伴い、商品一覧へのリンクURLなどが大きく変更となっております。</p>
<p>このページをブックマークに登録されていた方は、お手数ですがブックマークの更新をお願いいたします。</p>

	<p className="mt-5 text-center"><a href="/" className="btn btn-blue btn-lg" block size="lg">コスメ専用ストアTOPへ <i className="fa fa-arrow-right"></i></a></p>

						</Container>
</main>
<Footer/>
</div>
		);
	}
}


export default NotFound;
