import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class GuideFaq extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
				<Container>
					<div className="head-logo sec">
						<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
					</div>

						<div className="pan">
							<div><a href="/">TOP</a></div>
							<div><a href={'/guide/'}>ショッピングガイド</a></div>
							<div>よくあるご質問</div>
						</div>
					<h1>SHOPPING GUIDE<p>ショッピングガイド</p></h1>
 </Container>

<Container fluid={true}>
   <Row>
        <ul className="sub-menu">
							<li><a href="/guide/">ご注文の流れ</a></li>
							<li><a href="/guide/payment/">お支払いについて</a></li>
							<li><a href="/guide/send/">配送・送料について</a></li>
							<li><a href="/guide/#wrapping">ラッピングについて</a></li>
							<li><a href="/guide/faq/">よくあるご質問</a></li>
							<li><a href="/member/l">会員登録のおすすめ</a></li>
         </ul>
     </Row>
 </Container>

<Container className="longtxt">
<Container>
<h2>よくあるご質問</h2>
<h3><span>商品について</span></h3>
<Row>
<Col lg={{ span: 10, offset: 1 }} md={12}>
<div className="rule">
<dl>
<dt>Ｑ．在庫数以上のたくさんの点数が欲しいのですが？</dt>
<dd>メーカーで在庫の有無をお調べいたしますので、お問い合わせフォームよりお問い合わせください。</dd>

<dt>Ｑ．ラッピングして欲しいのですが</dt>
<dd>Ａ．ギフト包装は有料でお受けしております。ご購入手続きの際に選択項目がありますので、お選びください。</dd>

<dt>Ｑ．店頭で買いたいのですが、どこに売っていますか？</dt>
<dd>Ａ．店頭のでの販売に関しては、ご希望のエリア、商品IDを<a href="https://www.mindwave.co.jp/contact/">株式会社マインドウェイブ</a>へ直接お問い合わせくださいませ。
尚、各店での在庫の有無までは管理しておりませんので、該当商品が確実に店頭にあるかはお答えできませんので、あらかじめご了承ください。</dd>

</dl>
</div>
</Col>
</Row>
</Container>

<Container className=" longtxt">
<h3><span>会員登録について</span></h3>
<Row>
<Col lg={{ span: 10, offset: 1 }} md={12}>
<div className="rule">
<dl>
		<dt>Ｑ．会員登録の「検証コード」が届きません。</dt>
		<dd>Ａ．「@mindwave-store.com」のドメインを受信できるよう設定の上、「<a href="/member/resent/">検証コードの再送</a>」をお試しください。（「検証コード」の有効期限は24時間です。）<br/>
		携帯メールアドレスの方、フリーメールアドレスの方は迷惑メールとしてブロックされていることが多く発生しております。<br/>
		お使いのメールサービス、メールソフト、ウィルス対策ソフト等の設定により「迷惑メール」と認識され、メールが届かない場合がございます。
		「@mindwave-store.com」のドメインを受信できるよう設定をご確認いただきますようお願い申し上げます。 </dd>

	<dt>Ｑ．検証コードを発行しましたが「認識出来ないメール形式のため表示出来ません」と表示されてメールが見られません。</dt>
		<dd>Ａ．当サイトから送信されるメールはUTF-8キャラクタセットのbase64エンコード、multipart/mixedのHTMLメールとなっています。<br />
これらの新しい規格のメールを一部のau携帯電話のメールソフト側が表示出来ない事例が発生しております。<br />
携帯電話の設定で表示エンコードを変えてお試しいただくか、PC用等の別のメールアドレスで登録をお試しください。 </dd>

<dt>Ｑ．会員登録しようとしたら「ご入力を頂いたメールアドレスはすでに登録されています」と表示され登録が完了できません。</dt>
<dd>Ａ．メールアドレスの認証コードを入力した方 <i className="fas fa-caret-right"></i> <a href="/login/">ログイン画面</a>にてメールアドレスとパスワードを入力しお進みいただき、会員情報・発送先情報の入力をいただければ会員登録が完了します。<br />
メールアドレスの認証コードを入力していない方 <i className="fas fa-caret-right"></i> 「<a href="/member/resent/">検証コードの再送</a>」を行い、検証コードを入力してから、ログイン画面にお進みください。その後は会員情報・発送先情報の入力をいただければ会員登録完了となります。</dd>

</dl>
</div>
</Col>
</Row>
</Container>

<Container className=" longtxt">
<h3><span>ご購入手続きについて</span></h3>
<Row>
<Col lg={{ span: 10, offset: 1 }} md={12}>
<div className="rule">
<dl>
<dt>Ｑ．明細書が欲しい。</dt>
<dd>基本的に明細書は同梱しておりません。ご必要な場合は、ご注文時の備考欄にその旨お書き添えください。ご注文完了後の場合は、メール・お問い合わせフォームよりご要望の内容をご連絡くださいませ。</dd>
<dt>Ｑ．領収書の発行は出来ますか？</dt>
<dd>PDFデータでの発行が可能です。ご注文時、備考欄にご希望内容をお書き添えください。<br />
商品出荷完了後にメールにてデータをお送りいたします。</dd>

<dt>Ｑ．【送料込み】（もしくは【送料無料】）と書かれているのに送料が加算されます。</dt>
		<dd>Ａ．メーカー発送商品等、商品の組み合わせによって送料が必要な場合がございます。<br />また、【送料無料】商品は、北海道・沖縄県へのお届けの場合、追加送料が発生いたします。<br />いずれの場合も、ご注文確定メールにて最終金額をご案内いたしておりますので、ご確認のほどよろしくお願いいたします。 </dd>

<dt>Ｑ．注文してどのくらいで届きますか？</dt>
<dd>Ａ．ご注文いただきました後、スタッフより 『マインドウェイブ★オンラインストア｜ご注文承りました』 という件名のメールをお送りいたします。その後2～5営業日後（土日祝・年末年始・夏季休業などの休業日を除く）の発送となります。<br />
朝9時に注文を締め切り、その日の発送の手配をいたします。朝9時以降のご注文は翌日（土日祝日は翌営業日）の発送手配となります。</dd>
<dt>Ｑ．商品の発送状況を確認は出来ますか？</dt>
<dd>Ａ．発送完了後に、配送業者のお問い合わせ送り状NO.等を記載した 『出荷通知メール 』 をお送りしております。商品の配送状況は、配送業者のホームページ上にてお問い合わせ送り状NO.からご確認ください。詳細は、お送りする 『出荷通知メール』 をご覧ください。会員の方はログイン後、マイページからもご確認いただけます。</dd>
<dt>Ｑ．商品の同梱はできますか</dt>
<dd>商品によっては同梱できないものや、別途配送によりお届け日が異なるものがあります。あらかじめご了承ください。また、ご注文から間が空かず追加でご注文いただいたとき、お支払い方法・お届け先・配達希望日時が同一の場合は、同梱で商品をお送りさせていただく場合もございます。</dd>
<dt>Ｑ．外国への発送はできますか。</dt>
<dd>Ａ．誠に申し訳ございませんが、現在、海外への発送は承っておりません。ご了承ください。転送.comなどをご活用いただいております。</dd>

		<dt>Ｑ．メルマガが届きません。</dt>
		<dd>Ａ．迷惑メールフォルダへ振り分けられてしまうことがあります。 迷惑メールフォルダやゴミ箱にメールが届いていないかご確認ください。<br />
		それまで問題なく受信できていても、メールボックスが一杯だったり、一時的な理由でメールソフトやサーバーにブロックされる場合もございます。ブロックが継続されているお客様に関しましては、システムにより一時メルマガ配信を解除させていただく場合がございます。改めてメルマガを受信される場合は、「MY PAGE」→<a href="/mypage/profile/">「ご登録内容の変更」</a>より『メルマガ購読』を「はい」に設定してください。<br />
		尚、メールマガジンは「store_news@mindwave-store.com」というアドレスより送信しております。必ず受信できるよう設定をご確認くださいませ。</dd>

</dl>
</div>
</Col>
</Row>
</Container>

<Container className="longtxt">
<h3><span>返品・交換について</span></h3>
<Row>
<Col lg={{ span: 10, offset: 1 }} md={12}>
<div className="rule">
<dl>
<dt>Ｑ．注文を間違えた時は、注文後にキャンセルは出来きますか？</dt>
<dd>弊社では、お客様に少しでも早く商品をお届けしたい為、『ご注文確定メ－ル』（件名：ご注文承りました ｜マインドウェイブ★オンラインストア）をお客様へ送信後、すぐに出荷に取りかかる可能性がございます。万が一、ご変更・キャンセルをご希望の場合は、『ご注文確定メ－ル』 を添付の上、正しいご注文内容、又はキャンセル内容を記載し、早急にご返信ください。<br />
但し、お客様からのご連絡が出荷後であった場合（休業日・営業時間終了後も含めて）は、ご注文内容の変更、キャンセル、返品、交換はお受けできませんので、予めご了承ください。</dd>

<dt>Ｑ．長期不在で返品されたのですが？</dt>
<dd>Ａ．商品発送後、お届け時にご不在の場合はヤマト運輸（または郵便局）の不在票をポストに入れております。不在票に記載の配達店へ再配達のご依頼をしてください。運送業者での商品のお預かりは約１週間です。１週間を超えて長期ご不在で運送業者及び当社からのご連絡がつかない場合は返品となり、商品がセンターへ戻ってしまいますので、お早目に商品をお引き取りくださいますようお願いいたします。長期不在による返送された商品の再発送には再度送料、代引き・後払い手数料をいただきます。
<p className="red">ご連絡のない受け取り拒否や常識の範囲を超える要望などを繰り返されるお客様には、お取引を控えさせていただく場合がございます。予めご了承ください。</p></dd>

<dt>Ｑ．思った感じと違った。気に入らなかった。返品できますか？</dt>
<dd>Ａ．お客様都合（注文間違い、イメージと違う等）による返品・交換は承っておりません。よくご確認・ご検討の上、ご購入くださいませ。</dd>
</dl>
</div>
</Col>
</Row>
</Container>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(GuideFaq);
