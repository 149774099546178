import React from 'react';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';

class OtherFurupurucosme extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
			<Container>
				<div className="head-logo sec">
					<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
				</div>

					<div className="pan">
						<div><a href="/">TOP</a></div>
						<div>フルプルコスメ</div>

					</div>
</Container>

 <Container className="mb-5">
	 <Row>
		 <Col sm={12} md={6} lg={7} className="align-self-center"><Image src="/img/others/furupuru/img_head03.jpg" alt="FURUPURU COSME(フルプルコスメ)" fluid /></Col>
		 <Col sm={12} md={6} lg={5} className="align-self-center mt-5">
<Image src="/img/others/furupuru/ttl_furupuru.png" alt="FURUPURU COSME(フルプルコスメ)" fluid />
<p className="pt-5 text-center">「かわいい」「ふっくらプルプルのお肌」に憧れる<br /><br className="br-sp" />
全ての“女子”を私たちFURUPURU COSMEは<br className="br-sp" />応援しています。</p>
<p className="pt3 text-center">優れた品質と魅力的なキャラクターで<br className="br-sp" />小さなお子様から<br />
大人まで安心して使える、<br className="br-sp" />日本製の化粧品をお届けしたい。</p>
<p className="pt-5 text-center">それが私たちFURUPURU COSMEの<br className="br-sp" />ポリシーです。</p>
</Col>
</Row>
</Container>

<Container className="mt-5">

<h3><span><i class="fas fa-search"></i> 商品カテゴリから選ぶ</span></h3>
<ul className="pickupitem">
<li><a href="#cream"><Image src="/img/others/furupuru/img_item01.png" /><p>フルプル<br />クリーム</p></a></li>
{/*<li><a href="#cologne"><Image src="/img/others/furupuru/img_item02.png" /><p>フルプル<br />コロン</p></a></li>*/}
<li><a href="#handcream"><Image src="/img/others/furupuru/img_item03.png" /><p>フルプル<br />ハンド&amp;ネイルクリーム</p></a></li>
<li><a href="#facemask"><Image src="/img/others/furupuru/img_item04.png" /><p>フルプル<br />フェイスマスク</p></a></li>
<li><a href="#bathsolt"><Image src="/img/others/furupuru/img_item05.png" /><p>フルプル<br />バスソルト</p></a></li>
</ul>
</Container>
<Container fluid={true} className=" bg_yellow py-5 mb-5" >
<Container className="chara_list">
<h3><span><i class="fas fa-search"></i> キャラクター・デザインから選ぶ</span></h3>
<p className="text-center pb-5">人気キャラクターのフルプルコスメを全部揃えちゃおう</p>
<Row>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-ごろごろにゃんすけ/"><Image src="/img/others/furupuru/character_icon01.png"  fluid />
<h5>ごろごろにゃんすけ</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-しばんばん/"><Image src="/img/others/furupuru/character_icon02.png"  fluid />
<h5>しばんばん</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-ぴよこ豆/"><Image src="/img/others/furupuru/character_icon11.png"  fluid />
<h5>ぴよこ豆</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-KIGURU ME/"><Image src="/img/others/furupuru/character_icon03.png"  fluid />
<h5>KIGURU･ME</h5></a></Col>

<Col xs={4} sm={3}><a href="/category/キャラクターから探す-にゃばんばん"><Image src="/img/others/furupuru/character_icon12.png"  fluid />
<h5>にゃばんばん</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-デリシャススターズ/"><Image src="/img/others/furupuru/character_icon08.png"  fluid  />
<h5>デリシャススターズ</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-リラックマ/"><Image src="/img/others/furupuru/character_icon05.png"  fluid />
<h5>リラックマ</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-すみっコぐらし/"><Image src="/img/others/furupuru/character_icon13.png"  fluid />
<h5>すみっコぐらし</h5></a></Col>
{/* 
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-くまのがっこう/"><Image src="/img/others/furupuru/character_icon06.png"  fluid />
<h5>くまのがっこう</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-カピバラさん"><Image src="/img/others/furupuru/character_icon07.png"  fluid />
<h5>カピバラさん</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-ほわころくらぶ/"><Image src="/img/others/furupuru/character_icon04.png"  fluid />
<h5>ほわころくらぶ</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-BT21/"><Image src="/img/others/furupuru/character_icon14.png"  fluid  />
<h5>BT21</h5></a></Col>
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-カードキャプターさくら/"><Image src="/img/others/furupuru/character_icon15.png"  fluid />
<h5>カードキャプターさくら</h5></a></Col>*/}


<Col xs={4} sm={3}><a href="/category/キャラクターから探す-和柄シリーズ/"><Image src="/img/others/furupuru/character_icon16.png"  fluid className="bdrimg"/>
<h5>和柄シリーズ</h5></a></Col>
{/* <Col xs={4} sm={3}><a href="/category/キャラクターから探す-誕生月シリーズ/"><Image src="/img/others/furupuru/character_icon17.png"  fluid />
<h5>誕生月シリーズ</h5></a></Col>*/}
<Col xs={4} sm={3}><a href="/category/キャラクターから探す-その他/"><Image src="/img/others/furupuru/character_icon18.png"  fluid />
<h5>その他MWキャラクター</h5></a></Col>



</Row>
</Container>
</Container>
<Container id="cream" className="mt-5 pt-5">
<Row>
<Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 5, offset: 0 }}className="order-2 order-sm-2 order-md-2">
	<a href="/category/フルプルコスメ-フルプルクリーム/" ><Image src="/img/others/furupuru/cream_main02.jpg"  fluid /></a></Col>
<Col  xs={12} sm={12} md={7} className="order-1 order-sm-1 order-md-2">
		<picture><source media="(max-width: 767px)" srcSet="/img/others/furupuru/cream_main01_sp.png" />
			<Image src="/img/others/furupuru/cream_main01.png"  fluid />
		 </picture>
<p className="pt-5">構想から研究・開発まで7年を費やしたこだわりのフルプルクリーム。<br />
保湿成分をたっぷりと配合したオイルインクリームが、お肌にずーっと馴染んでベタつかず、潤いを与えてくれます。顔・髪・手・体など全身に使える万能クリームで、お子様から大人までご使用いただけます。</p>
<p>かわいいキャラクターのコンパクトな容器だから、外出先でもどこでも気軽に保湿ケアができます。</p>

<div className="adfree">
	<dl>
<dt>こだわりの<br />フリー処方</dt>
<dd>パラベン／サルフェート<br />合成香料／鉱物油</dd>
</dl>
</div>

</Col>

<Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }}  md={{ span: 12, offset: 0 }} className="order-3 order-sm-3">
	<picture><source media="(max-width: 767px)" srcSet="/img/others/furupuru/cream_seibun01_sp.png" />
		<Image src="/img/others/furupuru/cream_seibun01.png"  fluid />
	 </picture>
	 </Col>
</Row>
</Container>

{/* <Container fluid={true} className=" bg_yellow pt-5 pb-5">
<Container className="shibatan">
<h3><span>しばんばんお誕生月シリーズ</span></h3>
<Row>
<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93679/"><Image src="https://images.mindwave-store.com/public/93679_main.jpg"  fluid  />
<h5>フルプルクリーム 93679 しばんばん 1月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93680/"><Image src="https://images.mindwave-store.com/public/93680_main.jpg"  fluid  />
<h5>フルプルクリーム 93680 しばんばん 2月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93681/"><Image src="https://images.mindwave-store.com/public/93681_main.jpg"  fluid  />
<h5>フルプルクリーム 93681 しばんばん 3月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93682/"><Image src="https://images.mindwave-store.com/public/93682_main.jpg"  fluid  />
<h5>フルプルクリーム 93682 しばんばん 4月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93683/"><Image src="https://images.mindwave-store.com/public/93683_main.jpg"  fluid  />
<h5>フルプルクリーム 93683 しばんばん 5月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93684/"><Image src="https://images.mindwave-store.com/public/93684_main.jpg"  fluid  />
<h5>フルプルクリーム 93684 しばんばん 6月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93685/"><Image src="https://images.mindwave-store.com/public/93685_main.jpg"  fluid  />
<h5>フルプルクリーム 93685 しばんばん 7月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93686/"><Image src="https://images.mindwave-store.com/public/93686_main.jpg"  fluid  />
<h5>フルプルクリーム 93686 しばんばん 8月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93687/"><Image src="https://images.mindwave-store.com/public/93687_main.jpg"  fluid  />
<h5>フルプルクリーム 93687 しばんばん 9月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93688/"><Image src="https://images.mindwave-store.com/public/93688_main.jpg"  fluid  />
<h5>フルプルクリーム 93688 しばんばん 10月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://www.mindwave-store.com/item/93689/"><Image src="https://images.mindwave-store.com/public/93689_main.jpg"  fluid  />
<h5>フルプルクリーム 93689 しばんばん 11月</h5></a></Col>

<Col xs={6} sm={4} md={2}><a href="https://cosme.mindwave-store.com/item/93690/"><Image src="https://images.mindwave-store.com/public/93690_main.jpg"  fluid  />
<h5>フルプルクリーム 93690 しばんばん 12月</h5></a></Col>
</Row>
</Container>
</Container>*/} 

<Container>
<p className="text-center pt-5 pb-5"><a href="/category/フルプルコスメ-フルプルクリーム/" className="btn btn-lg btn-pink btn_w800">フルプルクリーム商品を見る <i className="fa fa-arrow-right"></i></a></p>
</Container>
{/* 
<Container id="cologne">
		<hr className="furupuruline" />
		<picture><a href="/category/フルプルコスメ-フルプルコロン/" ><source media="(max-width: 767px)" srcSet="/img/others/furupuru/cologne_main_sp.png" />
			<Image src="/img/others/furupuru/cologne_main.png"  fluid /></a>
			</picture>
<p>保湿成分を贅沢に配合した、新感覚のクリームタイプのコロンです。<br />
馴染みが良く優しく香りがまとえ、コンパクトサイズなので外出先でリフレッシュしたい時などにおすすめ。ネイル以外にも通常のフレグランス同様、手首・首筋・髪などに付けて香りをお楽しみください。</p>
<p>日常で乾燥の気になるところの保湿ケアとしてもお使いいただけます。</p>

<div className="adfree">
	<dl>
<dt>こだわりの<br />フリー処方</dt>
<dd>アルコール<br />鉱物油</dd>
</dl>
</div>


<Row>
<Col xs={6} sm={3}><a href="/search/フルプルコロン WF/"><Image src="/img/others/furupuru/cologne_aroma01.png"  fluid /></a>
<p className="psp">ジャスミンやスズラン、ローズなど優美な花々を合わせたエレガントな香り<br />
<a href="/search/フルプルコロン WF/" className="btn btn-blue mt-3">商品はこちら</a></p></Col>
<Col xs={6} sm={3}><a href="/search/フルプルコロン AF/"><Image src="/img/others/furupuru/cologne_aroma02.png"  fluid /></a>
<p className="psp">爽やかなシャボンの香りにみずみずしい果実や花を合わせた清々しい香り<br />
<a href="/search/フルプルコロン AF/" className="btn btn-blue mt-3">商品はこちら</a></p></Col>
<Col xs={6} sm={3}><a href="/search/フルプルコロン OF/"><Image src="/img/others/furupuru/cologne_aroma03.png"  fluid /></a>
<p className="psp">バニラのような甘い香りに、ピオニーとホワイトムスクを合わせた個性的な香り<br />
<a href="/search/フルプルコロン OF/" className="btn btn-blue mt-3">商品はこちら</a></p></Col>
<Col xs={6} sm={3}><a href="/search/フルプルコロン FF/"><Image src="/img/others/furupuru/cologne_aroma04.png"  fluid /></a>
<p className="psp">ライチのような甘い香りに品のあるローズを合わせたみずみずしい香り<br />
<a href="/search/フルプルコロン FF/" className="btn btn-blue mt-3">商品はこちら</a></p></Col>
</Row>
</Container>

 <Container fluid={true} className=" bg_yellow pt-5 pb-5">
<Container>
<h3><span>全ての香りを楽しめる4種セット</span></h3>
<Row>
<Col xs={12} sm={6} className="pb-5"><a href="https://cosme.mindwave-store.com/item/CA190401/"><Image src="https://images.mindwave-store.com/public/CA190401_main.jpg" className="hanbun"  />
<Image src="https://images.mindwave-store.com/public/CA190401_sub01.jpg" className="hanbun"  />
<h5>気分でころころ!しばんばんコロン4種セット</h5></a>
</Col>

<Col xs={12} sm={6} className="pb-5"><a href="https://cosme.mindwave-store.com/item/CA190402/"><Image src="https://images.mindwave-store.com/public/CA190402_main.jpg" className="hanbun"  />
<Image src="https://images.mindwave-store.com/public/CA190402_sub01.jpg" className="hanbun"  />
<h5>気分でころころ!にゃんすけコロン4種セット</h5></a></Col>
</Row>
</Container>
</Container>

<Container>
<p className="text-center pt-5 pb-5"><a href="/category/フルプルコスメ-フルプルコロン/" className="btn btn-lg btn-pink btn_w800">フルプルコロン商品を見る <i className="fa fa-arrow-right"></i></a></p>
</Container>
*/}
<Container id="handcream">
	<hr className="furupuruline" />
		<picture><a href="/category/フルプルコスメ-フルプルハンド&ネイルクリーム/" ><source media="(max-width: 767px)" srcSet="/img/others/furupuru/hand_main01_sp.png" />
			<Image src="/img/others/furupuru/hand_main01.png"  fluid /></a>
			</picture>

		<picture><source media="(max-width: 767px)" srcSet="/img/others/furupuru/hand_frame01_sp.png" />
			<Image src="/img/others/furupuru/hand_frame01.png"  fluid />
			</picture>
<Row>
<Col xs={{ span: 10, offset: 1 }}  sm={{ span: 4, offset: 0 }}> <Image src="/img/others/furupuru/hand_urusara.png"  fluid /></Col>
<Col xs={12} sm={8} className="pt-5">
<p>クリームを手に伸ばすと、贅沢に配合した“潤いウォーター”が溢れ出し、しっかりと保湿します。<br />
べたつかず瞬時にさらっとするので、スマホを触ったり、本をめくるなどの次の動作に取り掛かりやすくなっています。</p>
<p>ネイルケアにも使え、美容成分たっぷりの潤いヴェールが手肌をキレイに魅せてくれます。<br />
また、軽い付け心地だからこそ季節を問わずにお使い頂けます。</p>
<div className="adfree">
	<dl>
<dt>こだわりの<br />フリー処方</dt>
<dd>着色料／パラベン／サルフェート／鉱物油<br />紫外線吸収剤／アルコール／安息香酸</dd>
</dl>
</div>
</Col>
</Row>
</Container>

<Container>
<p className="text-center pt-5 pb-5"><a href="/category/フルプルコスメ-フルプルハンド&ネイルクリーム/" className="btn btn-lg btn-pink btn_w800">フルプルハンド&ネイルクリーム商品を見る <i className="fa fa-arrow-right"></i></a></p>
</Container>

<Container id="facemask">
	<hr className="furupuruline" />

		<picture><source media="(max-width: 767px)" srcSet="/img/others/furupuru/facemask_main01_sp.jpg"  fluid />
			<Image src="/img/others/furupuru/facemask_main01.png"  fluid />
		 </picture>

<Row>
<Col sm={12} md={{ span: 8, offset: 4 }}>
<p>自然生まれの美容成分たっぷりのぷるぷるジュレを、マスクにぎゅっと閉じ込めました。<br />
ジュレだから液垂れしにくく、やわらかシートがお肌にピタッと密着して、潤いとハリを与えます。<br />
日常で乾燥の気になるところの保湿ケアとしてもお使いいただけます</p>
<div className="adfree">
	<dl>
<dt>こだわりの<br />フリー処方</dt>
<dd>合成香料／鉱物油／シリコン／サルフェート<br />着色料／パラベン／安息香酸／紫外線吸収剤</dd>
</dl>
</div>


</Col>
</Row>
</Container>

<Container>
<p className="text-center pt-5 pb-5"><a href="/category/フルプルコスメ-フルプルフェイスマスク/" className="btn btn-lg btn-pink btn_w800">フルプルフェイスマスク商品を見る <i className="fa fa-arrow-right"></i></a></p>
</Container>

<Container id="bathsolt">
	<hr className="furupuruline" />
<Row>
<Col  xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }}  md={{ span: 4, offset: 0 }} className="order-3 order-sm-1"><Image src="/img/others/furupuru/bath_img01.jpg" className="img-fluid pt-5" alt="バスソルトイメージ" /></Col>
<Col xs={12} md={4} className="order-1 order-sm-2">
	<picture><source media="(max-width: 767px)" srcSet="/img/others/furupuru/bath_ttl_sp.png" />
		<Image src="/img/others/furupuru/bath_ttl.png" className="img-fluid pt-5" />
		</picture>
<p className="text-center pt-5">汗をかいてすっきり。<br />芯までポカポカ。<br />天然オイルのベールに包まれ、<br />うるすべ美肌。</p></Col>
<Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }}  md={{ span: 4, offset: 0 }} className="order-2 order-sm-3"><Image src="/img/others/furupuru/batn_seibun.jpg"  fluid /></Col>
</Row>
<p><picture><a href="/category/フルプルコスメ-フルプルバスソルト/" ><source media="(max-width: 767px)" srcSet="/img/others/furupuru/bath_item_sp.png" />
				<Image src="/img/others/furupuru/bath_item.jpg" className="img-fluid pt-5" /></a>
				</picture></p>

				<div className="adfree">
					<dl>
				<dt>こだわりの<br />フリー処方</dt>
				<dd>鉱物油／石油系界面活性剤<br />パラベン／シリコン</dd>
				</dl>
				</div>
</Container>

<Container>
<p className="text-center pt-5 pb-5"><a href="/category/フルプルコスメ-フルプルバスソルト/" className="btn btn-lg btn-pink btn_w800">フルプルバスソルト商品を見る <i className="fa fa-arrow-right"></i></a></p>
</Container>


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OtherFurupurucosme);
