import { API, graphqlOperation } from 'aws-amplify';

const WishList = {};

WishList.Get = async function ()  {

	try{

		const getQuery = `query getWishListCosme {
			getWishListCosme {
				items{
					code
					name
					date_add
					date_edit
				}
			}
		}`;
		const ref = await API.graphql(graphqlOperation(getQuery));
		const items = ref.data.getWishListCosme.items;
		
		items.sort(function(a,b){
			if(a.date_edit > b.date_edit) return -1;
			if(a.date_edit < b.date_edit) return 1;
			return 0;
		});
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

WishList.Add = async function (code)  {

	try{
		//パラメータ確認
		if(!code){ return({ error : "商品コードがありません。" }); }
		
		//データ取得
		const getQuery = `query getItem($code: String!) {
			getItem(code: $code) {
				name
				zaiko
				en_kbn
			}
		}`;
		const getData    = { code: code };
		const refItem    = await API.graphql(graphqlOperation(getQuery, getData));
		const item       = refItem.data.getItem;

		//対象商品の有無
		if(item === null){ return({ error : "商品がありません。" }); }

		//値取得
		const name       = item.name;
		//const zaiko      = Number(item.zaiko);
		const en_kbn     = item.en_kbn;
		
		//エラー条件
		if(en_kbn !== true){ return({ error : "商品が有効でありません。" }); }
		//if(zaiko <= 0){ return({ error : "商品の在庫がありません。" }); }
		
		//総件数チェック
		const countQuery = `query countWishListCosme {
			countWishListCosme {
				scannedCount
			}
		}`;
		const refCart = await API.graphql(graphqlOperation(countQuery));
		const count = refCart.data.countWishListCosme.scannedCount;

		//カートは1度に100件まで
		if(count >= 100){ return({ error : "20件の商品までしか入れられません。" }); }

		//チェック
		const checkQuery = `query getWishListItemCosme($code: String!) {
			getWishListItemCosme(code: $code) {
				code
			}
		}`;
		const checkData = {
			code  : code,
		};
		const refCheck  = await API.graphql(graphqlOperation(checkQuery, checkData));
		const check     = refCheck.data.getWishListItemCosme;

		if(check === null){
			
			//新規
			const addQuery = `mutation addWishListCosme($data: WishListInput!) {
				addWishListCosme(input: $data) {
					code
				}
			}`;
			const addData = {
				data : {
					code       : code,
					name       : name,
				}
			};
			await API.graphql(graphqlOperation(addQuery, addData));
		}
		else{
			//更新
			const updateQuery = `mutation updateWishListCosme($data: WishListInput!) {
				updateWishListCosme(input: $data) {
					code
				}
			}`;
			const updateData = {
				data : {
					code       : code,
					name       : name,
				}
			};
			await API.graphql(graphqlOperation(updateQuery, updateData));
		}
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

WishList.Remove = async function (code)  {

	try{
		//パラメータ確認
		if(!code){ return({ error : "商品コードがありません。" }); }

		//削除
		const Query = `mutation removeWishListCosme($code: String!) {
			removeWishListCosme(code: $code) {
				code
			}
		}`;
		const Data = { code: code };
		
		await API.graphql(graphqlOperation(Query, Data));
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

export { WishList };


