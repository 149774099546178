import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import NotFound from './Pages/@Include/NotFound';
import Main from './Pages/Main';
import CartMain from './Pages/Cart/Main';
import ItemMain from './Pages/Item/Main';
import CategoryMain from './Pages/Category/Main';
import SearchMain from './Pages/Search/Main';
import OrderMain from './Pages/Order/Main';
import MyPageMain from './Pages/MyPage/Main';
import MyPageUnsub from './Pages/MyPage/Unsub';
import MyPageProfile from './Pages/MyPage/Profile';
import MyPageEmailVerify from './Pages/MyPage/EmailVerify';
import MyPagePassword from './Pages/MyPage/Password';
import MyPageAddress from './Pages/MyPage/Address';
import MyPageAddressDetails from './Pages/MyPage/AddressDetails';
import MyPageAddressNew from './Pages/MyPage/AddressNew';
import MyPageOrder from './Pages/MyPage/Order';
import MyPageOrderDetails from './Pages/MyPage/OrderDetails';
import MyPageWishList from './Pages/MyPage/WishList';
import MemberMain from './Pages/Member/Main';
import MemberVerify from './Pages/Member/Verify';
import MemberResent from './Pages/Member/Resent';
import LoginMain from './Pages/Login/Main';
import LogoutMain from './Pages/Logout/Main';
import GuideMain from './Pages/Guide/Main';
import GuidePayment from './Pages/Guide/Payment';
import GuideSend from './Pages/Guide/Send';
import GuideFaq from './Pages/Guide/Faq';
import CompanyMain from './Pages/Company/Main';
import CompanyRaw from './Pages/Company/Raw';
import CompanyPolicy from './Pages/Company/Policy';
import CompanyTerms from './Pages/Company/Terms';
import ContactMain from './Pages/Contact/Main';
import OthersZeitakubihadado from './Pages/Others/Zeitakubihadado';
import OthersFurupurucosme from './Pages/Others/Furupurucosme';
import OthersMatcha_mask from './Pages/Others/Matcha_mask';
//import OthersMain from './Pages/Others/Main';

class App extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<div className="wrap">
					<Switch>
						<Route exact path='/' component={Main} />
						<Route path='/order/' component={OrderMain} />
						<Route path='/search/:keyword/:page/:sort/' component={SearchMain} />
						<Route path='/search/:keyword/:page/' component={SearchMain} />
						<Route path='/search/:keyword/' component={SearchMain} />
						<Route path='/search/' component={SearchMain} />
						<Route path='/category/:category/:page/:sort/' component={CategoryMain} />
						<Route path='/category/:category/:page/' component={CategoryMain} />
						<Route path='/category/:category/' component={CategoryMain} />
						<Route path='/category/' component={CategoryMain} />
						<Route path='/cart/' component={CartMain} />
						<Route path='/item/:code/' component={ItemMain} />
						<Route path='/mypage/wishlist/' component={MyPageWishList} />
						<Route path='/mypage/order/:orderno/' component={MyPageOrderDetails} />
						<Route path='/mypage/order/' component={MyPageOrder} />
						<Route path='/mypage/address/new/' component={MyPageAddressNew} />
						<Route path='/mypage/address/:addressid/' component={MyPageAddressDetails} />
						<Route path='/mypage/address/' component={MyPageAddress} />
						<Route path='/mypage/password/' component={MyPagePassword} />
						<Route path='/mypage/emailverify/' component={MyPageEmailVerify} />
						<Route path='/mypage/profile/' component={MyPageProfile} />
						<Route path='/mypage/unsub/' component={MyPageUnsub} />
						<Route path='/mypage/' component={MyPageMain} />
						<Route path='/member/resent/' component={MemberResent} />
						<Route path='/member/verify/' component={MemberVerify} />
						<Route path='/member/' component={MemberMain} />
						<Route path='/login/' component={LoginMain} />
						<Route path='/logout/' render={props => <LogoutMain {...this.props} />} />
						<Route path='/guide/payment' component={GuidePayment} />
					  	<Route path='/guide/send' component={GuideSend} />
						<Route path='/guide/faq' component={GuideFaq} />
						<Route path='/guide' component={GuideMain} />
					  	<Route path='/others/furupurucosme' component={OthersFurupurucosme} />
						<Route path='/others/zeitakubihadado' component={OthersZeitakubihadado} />
						<Route path='/others/matcha_mask' component={OthersMatcha_mask} />
						<Route path='/others' component={GuideMain} />
						<Route path='/company/law' component={CompanyRaw} />
						<Route path='/company/policy' component={CompanyPolicy} />
						<Route path='/company/terms' component={CompanyTerms} />
						<Route path='/company' component={CompanyMain} />
						<Route path='/contact' component={ContactMain} />
						<Route component={NotFound} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
