import { API, graphqlOperation, /*Auth*/ } from 'aws-amplify';

const Cart = {};

Cart.Get = async function ()  {

	try{

		/*
		const currentCredentials = await Auth.currentCredentials();
		console.log(currentCredentials);
		const IdentityId         = currentCredentials.data.IdentityId;
		console.log(IdentityId);
		const authenticated         = currentCredentials.authenticated;
		console.log(authenticated);
		*/

		const getQuery = `query getCartCosme {
			getCartCosme {
				items{
					code
					name
					price
					sub_price
					piece
					piece_type
					error
					date_add
					date_edit
				}
			}
		}`;
		const ref = await API.graphql(graphqlOperation(getQuery));
		const items = ref.data.getCartCosme.items;
		
		//合計集計
		let total_price = 0;
		let total_piece = 0;
		
		//カートの中身をチェック
		for(const item of items){
			const sub_price = item.sub_price ? Number(item.sub_price) : 0;
			const piece     = item.piece     ? Number(item.piece)     : 0;
			total_price     = total_price + sub_price;
			total_piece     = total_piece + piece;
		}
		
		return ({
			total_price : total_price,
			total_piece : total_piece,
			items: items,
			error: null,
		});
	}
	catch(error) {
		return({
			total_price : null,
			total_piece : null,
			items: null,
			error: error,
		});
	}
}

Cart.Check = async function ()  {

	try{

		//カートの中身を取得
		const getQuery = `query getCartCosme {
			getCartCosme {
				items{
					code
					name
					price
					sub_price
					piece
					piece_type
					error
					date_add
					date_edit
				}
			}
		}`;
		const ref = await API.graphql(graphqlOperation(getQuery));
		const items = ref.data.getCartCosme.items;
		
		//合計集計
		let total_price = 0;
		let total_piece = 0;
		
		//カートの中身をチェック
		for(const item of items){
			
			//商品コード
			const code      = item.code;
			const sub_price = item.sub_price ? Number(item.sub_price) : 0;
			const piece     = item.piece     ? Number(item.piece)     : 0;
			
			//商品取得
			const getQuery = `query getItem($code: String!) {
				getItem(code: $code) {
					name
					price
					piece_type
					zaiko
					en_kbn
				}
			}`;
			const getData    = { code: code };
			const refItem    = await API.graphql(graphqlOperation(getQuery, getData));
			const getItem    = refItem.data.getItem;
			
			//エラー文字
			let error = '';

			//パラメータ
			let name       = item.name;
			let price      = item.price;
			let piece_type = item.piece_type;
			let zaiko      = 0;
			let zaiko_flag = false;
			let en_kbn     = false;

			//対象商品があるかどうか
			if(getItem === null){
				error = '該当の商品が見当たりません。こちらの商品は現在ご購入いただけません。カートから削除してください。';
			}
			else{
				//価格が異なればエラー
				if(price !== Number(getItem.price ? Number(getItem.price) : 0)){ error = '申し訳ございませんが、商品情報を変更しております。変更内容をご確認ください。'; }

				//値取得
				name       = getItem.name       ? getItem.name          : '';
				price      = getItem.price      ? Number(getItem.price) : 0;
				piece_type = getItem.piece_type ? getItem.piece_type    : '';
				zaiko      = getItem.zaiko      ? Number(getItem.zaiko) : 0;
				zaiko_flag = getItem.zaiko_flag ? true                  : false;
				en_kbn     = getItem.en_kbn     ? true                  : false;
			
				if(en_kbn !== true){ error = '商品が有効でありません。こちらの商品は現在ご購入いただけません。カートから削除してください。'; }
				if(zaiko_flag === false){
					if(zaiko <= 0)     { error = '商品の在庫がありません。こちらの商品は現在ご購入いただけません。カートから削除してください。'; }
					if(zaiko < piece)  { error = '商品の在庫が足りません。数量を変更してください。'; }
				}
			}

			//エラーがある場合
			if(error){
				//更新
				const updateQuery = `mutation updateCartCosme($data: CartInput!) {
					updateCartCosme(input: $data) {
						code
					}
				}`;
				const updateData = {
					data : {
						code       : code,
						name       : name,
						price      : price,
						sub_price  : Number(piece) * Number(price),
						piece      : piece,
						piece_type : piece_type,
						error      : error,
					}
				};
				await API.graphql(graphqlOperation(updateQuery, updateData));
			}

			//集計
			total_price     = total_price + sub_price;
			total_piece     = total_piece + piece;

		}
		
		return ({
			total_price : total_price,
			total_piece : total_piece,
			items: items,
			error: null,
		});
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

Cart.Add = async function (code, piece)  {

	try{
		//パラメータ確認
		if(!code){ return({ error : "商品コードがありません。" }); }
		if(!piece){ piece = 1;   }
		if(piece <= 0){ piece = 1;   }
		if(piece > 99){ return({ error : "カートには1種類の商品は99個までしか入れられません。" }); }

		//データ取得
		const getQuery = `query getItem($code: String!) {
			getItem(code: $code) {
				name
				price
				piece_type
				zaiko
				en_kbn
			}
		}`;
		const getData    = { code: code };
		const refItem    = await API.graphql(graphqlOperation(getQuery, getData));
		const item       = refItem.data.getItem;

		//対象商品の有無
		if(item === null){ return({ error : "商品がありません。" }); }

		//値取得
		const name       = item.name;
		const price      = Number(item.price);
		const piece_type = item.piece_type;
		const zaiko      = Number(item.zaiko);
		const en_kbn     = item.en_kbn;
		
		//エラー条件
		if(en_kbn !== true){ return({ error : "商品が有効でありません。" }); }
		if(zaiko <= 0){ return({ error : "商品の在庫がありません。" }); }
		if(zaiko < piece){ return({ error : "商品の在庫が足りません。" }); }
		
		//総件数チェック
		const countQuery = `query countCartCosme {
			countCartCosme {
				scannedCount
			}
		}`;
		const refCart = await API.graphql(graphqlOperation(countQuery));
		const count = refCart.data.countCartCosme.scannedCount;

		//カートは1度に100件まで
		if(count >= 100){ return({ error : "カートには100種類の商品までしか入れられません。" }); }

		//チェック
		const checkQuery = `query getCartItemCosme($code: String!) {
			getCartItemCosme(code: $code) {
				piece
			}
		}`;
		const checkData = { code: code };
		const refCheck  = await API.graphql(graphqlOperation(checkQuery, checkData));
		const check     = refCheck.data.getCartItemCosme;

		if(check === null){
			
			//新規
			const addQuery = `mutation addCartCosme($data: CartInput!) {
				addCartCosme(input: $data) {
					code
				}
			}`;
			const addData = {
				data : {
					code       : code,
					name       : name,
					price      : price,
					sub_price  : Number(piece) * Number(price),
					piece      : piece,
					piece_type : piece_type,
				}
			};
			await API.graphql(graphqlOperation(addQuery, addData));
		}
		else{
			//元のアイテム数
			const _piece = Number(check.piece);
			//更新
			const updateQuery = `mutation updateCartCosme($data: CartInput!) {
				updateCartCosme(input: $data) {
					code
				}
			}`;
			const updateData = {
				data : {
					code       : code,
					name       : name,
					price      : price,
					sub_price  : Number((Number(piece) + Number(_piece)) * price),
					piece      : Number(piece) + Number(_piece),
					piece_type : piece_type,
				}
			};
			await API.graphql(graphqlOperation(updateQuery, updateData));
		}
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

Cart.Update = async function (code, piece)  {

	try{
		//パラメータ確認
		if(!code){ return({ error : "商品コードがありません。" }); }
		if(!piece){ piece = 0; }
		if(piece <= 0){ piece = 0; }
		if(piece > 999){ return({ error : "カートには1種類の商品は999個までしか入れられません。" }); }

		//チェック
		const checkQuery = `query getCartItemCosme($code: String!) {
			getCartItemCosme(code: $code) {
				piece
				price
			}
		}`;
		const checkData = { code: code };
		const refCheck  = await API.graphql(graphqlOperation(checkQuery, checkData));
		const check     = refCheck.data.getCartItemCosme;

		if(check === null){
			return({ error : "カートには対象の商品がありません。" });
		}
		else{
			//元の価格
			const price = Number(check.price);
			
			//数値が0なら削除する
			if(piece === 0){
				await Cart.Remove(code);
			}
			else{
				//更新
				const updateQuery = `mutation updateCartCosme($data: CartInput!) {
					updateCartCosme(input: $data) {
						code
					}
				}`;
				const updateData = {
					data : {
						code       : code,
						sub_price  : Number(piece) * Number(price),
						piece      : Number(piece),
					}
				};
				await API.graphql(graphqlOperation(updateQuery, updateData));
			}
		}
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

Cart.Remove = async function (code)  {

	try{
		//パラメータ確認
		if(!code){ return({ error : "商品コードがありません。" }); }

		//削除
		const Query = `mutation removeCartCosme($code: String!) {
			removeCartCosme(code: $code) {
				code
			}
		}`;
		const Data = { code: code };
		
		await API.graphql(graphqlOperation(Query, Data));
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

export { Cart };


