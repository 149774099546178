import { API, graphqlOperation } from 'aws-amplify';

const Item = {};

Item.Get = async function (flag, code)  {

	try{
		//パラメータ確認
		if(!code){ return({ error : "商品コードがありません。" }); }

		const getQuery = `query getItem($code: String!) {
			getItem(code: $code) {
				name
				m_code
				m_name
				price
				price_notax
				piece_type
				title
				text_1
				text_2
				text_3
				text_4
				text_5
				select_1
				select_2
				select_3
				zaiko
				zaiko_flag
				flag_1
				flag_2
				flag_3
				flag_4
				flag_5
				flag_6
				flag_7
				flag_8
				flag_9
				en_kbn
				date_add
				date_edit
				stationery_flag
				cosme_flag
			}
		}`;
		const getData = { code : code };
		const ref = await API.graphql(graphqlOperation(getQuery, getData));
		const item = ref.data.getItem;

		const name        = item.name;
		const m_code      = item.m_code;
		const m_name      = item.m_name;
		const price       = item.price;
		const price_notax = item.price_notax;
		const piece_type  = item.piece_type;
		const title       = item.title;
		const text_1      = item.text_1;
		const text_2      = item.text_2;
		const text_3      = item.text_3;
		const text_4      = item.text_4;
		const text_5      = item.text_5;
		const select_1    = item.select_1;
		const select_2    = item.select_2;
		const select_3    = item.select_3;
		const zaiko       = item.zaiko;
		const zaiko_flag  = item.zaiko_flag;
		const flag_1      = item.flag_1;
		const flag_2      = item.flag_2;
		const flag_3      = item.flag_3;
		const flag_4      = item.flag_4;
		const flag_5      = item.flag_5;
		const flag_6      = item.flag_6;
		const flag_7      = item.flag_7;
		const flag_8      = item.flag_8;
		const flag_9      = item.flag_9;
		const en_kbn      = item.en_kbn;
		const date_add    = item.date_add;
		const date_edit   = item.date_edit;
		const stationery_flag = item.stationery_flag;
		const cosme_flag  = item.cosme_flag;

		//表示する種別
		let view_flag = false;
		if(flag === 'stationery' && stationery_flag){
			view_flag = true;
		}
		if(flag === 'cosme' && cosme_flag){
			view_flag = true;
		}

		if(en_kbn === true && view_flag){
			return ({
				item: {
					code        : code,
					name        : name,
					m_code      : m_code,
					m_name      : m_name,
					price       : price,
					price_notax : price_notax,
					piece_type  : piece_type,
					title       : title,
					text_1      : text_1,
					text_2      : text_2,
					text_3      : text_3,
					text_4      : text_4,
					text_5      : text_5,
					select_1    : select_1,
					select_2    : select_2,
					select_3    : select_3,
					zaiko       : zaiko,
					zaiko_flag  : zaiko_flag,
					flag_1      : flag_1,
					flag_2      : flag_2,
					flag_3      : flag_3,
					flag_4      : flag_4,
					flag_5      : flag_5,
					flag_6      : flag_6,
					flag_7      : flag_7,
					flag_8      : flag_8,
					flag_9      : flag_9,
					en_kbn      : en_kbn,
					date_add    : date_add,
					date_edit   : date_edit,
					stationery_flag : stationery_flag,
					cosme_flag  : cosme_flag,
				},
				error: null,
			});
		}
		else{
			return ({
				item: null,
				error: '商品が見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			item: null,
			error: error,
		});
	}
}

Item.Query = async function (flag, keyword, page, size)  {

	try{

		const from = (page === 1 || !page) ?  0 : Number((Number(page) - 1) * Number(size) ) ;
		size = size ? size : 50;

		//アイテムの取得
		const Query = `query searchItem($flag: String!, $keyword: String!, $from: Int!, $size: Int!) {
			searchItem(flag: $flag, keyword: $keyword, from: $from, size: $size) {
				items{
					code
					name
					m_code
					m_name
					price
					title
					text_1
					text_2
					text_3
					text_4
					text_5
					zaiko
				}
				scannedCount
			}
		}`;
		const Data = {
			flag    : flag,
			keyword : keyword,
			from    : from,
			size    : size,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		//console.log(ref);
		const Items = ref.data.searchItem.items;
		const Count = ref.data.searchItem.scannedCount;

		return ({
			items: Items,
			count: Count,
			error: null,
		});
	}
	catch(error) {
		return({
			items: null,
			count: 0,
			error: error,
		});
	}
}

Item.QueryV2 = async function (flag, keyword, page, size, sortname)  {

	try{

		const from = (page === 1 || !page) ?  0 : Number((Number(page) - 1) * Number(size) ) ;
		size  = size ? size : 50;

		let sort  = '日付';
		let order = '降順';

		if(sortname === '新しい順'){
			sort  = '日付';
			order = '降順';
		}
		else if(sortname === '安い順'){
			sort  = '価格';
			order = '昇順';
		}
		else if(sortname === '高い順'){
			sort  = '価格';
			order = '降順';
		}
		else if(sortname === '商品名順（昇順）'){
			sort  = '商品名';
			order = '昇順';
		}
		else if(sortname === '商品名順（降順）'){
			sort  = '商品名';
			order = '降順';
		}

		if(sort === '価格'){
			sort = 'price';
		}
		else if(sort === '日付'){
			sort = 'date_add'; //date_editから変更 2020.10.22
		}
		else if(sort === '商品名'){
			sort = 'name.keyword';
		}

		if(order === '降順'){
			order = 'desc';
		}
		else if(order === '昇順'){
			order = 'asc';
		}

		//アイテムの取得
		const Query = `query searchItemV2($flag: String!, $keyword: String!, $from: Int!, $size: Int!, $sort: String!, $order: String!) {
			searchItemV2(flag: $flag, keyword: $keyword, from: $from, size: $size, sort: $sort, order: $order) {
				items{
					code
					name
					m_code
					m_name
					price
					title
					text_1
					text_2
					text_3
					text_4
					text_5
					flag_3
					zaiko
					zaiko_flag
				}
				scannedCount
			}
		}`;
		const Data = {
			flag    : flag,
			keyword : keyword,
			from    : from,
			size    : size,
			sort    : sort,
			order   : order,
		};
		//console.log(Data);
		const ref = await API.graphql(graphqlOperation(Query, Data));
		//console.log(ref);
		const Items = ref.data.searchItemV2.items;
		const Count = ref.data.searchItemV2.scannedCount;

		return ({
			items: Items,
			count: Count,
			error: null,
		});
	}
	catch(error) {
		//console.log(error);
		return({
			items: null,
			count: 0,
			error: error,
		});
	}
}

Item.GetAll = async function (flag, page, size)  {

	try{

		const from = (page === 1 || !page) ?  0 : Number((Number(page) - 1) * Number(size) ) ;
		size = size ? size : 50;

		//アイテムの取得
		const Query = `query searchItemAll($flag: String!, $from: Int!, $size: Int!) {
			searchItemAll(flag: $flag, from: $from, size: $size) {
				items{
					code
					name
					m_code
					m_name
					price
					title
					text_1
					text_2
					text_3
					text_4
					text_5
					zaiko
				}
				scannedCount
			}
		}`;
		const Data = {
			flag    : flag,
			from    : from,
			size    : size,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const Items = ref.data.searchItemAll.items;
		const Count = ref.data.searchItemAll.scannedCount;

		return ({
			items: Items,
			count: Count,
			error: null,
		});
	}
	catch(error) {
		return({
			items: null,
			count: 0,
			error: error,
		});
	}
}

Item.GetAllV2 = async function (flag, page, size, sortname)  {

	try{

		const from = (page === 1 || !page) ?  0 : Number((Number(page) - 1) * Number(size) ) ;
		size = size ? size : 50;

		let sort  = '日付';
		let order = '降順';

		if(sortname === '新しい順'){
			sort  = '日付';
			order = '降順';
		}
		else if(sortname === '安い順'){
			sort  = '価格';
			order = '昇順';
		}
		else if(sortname === '高い順'){
			sort  = '価格';
			order = '降順';
		}
		else if(sortname === '商品名順（昇順）'){
			sort  = '商品名';
			order = '昇順';
		}
		else if(sortname === '商品名順（降順）'){
			sort  = '商品名';
			order = '降順';
		}

		if(sort === '価格'){
			sort = 'price';
		}
		else if(sort === '日付'){
			sort = 'date_add'; //date_editから変更 2020.10.22
		}
		else if(sort === '商品名'){
			sort = 'name.keyword';
		}

		if(order === '降順'){
			order = 'desc';
		}
		else if(order === '昇順'){
			order = 'asc';
		}

		//アイテムの取得
		const Query = `query searchItemAllV2($flag: String!, $from: Int!, $size: Int!, $sort: String!, $order: String!) {
			searchItemAllV2(flag: $flag, from: $from, size: $size, sort: $sort, order: $order) {
				items{
					code
					name
					m_code
					m_name
					price
					title
					text_1
					text_2
					text_3
					text_4
					text_5
					flag_3
					zaiko
					zaiko_flag
				}
				scannedCount
			}
		}`;
		const Data = {
			flag    : flag,
			from    : from,
			size    : size,
			sort    : sort,
			order   : order,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const Items = ref.data.searchItemAllV2.items;
		const Count = ref.data.searchItemAllV2.scannedCount;

		return ({
			items: Items,
			count: Count,
			error: null,
		});
	}
	catch(error) {
		return({
			items: null,
			count: 0,
			error: error,
		});
	}
}

Item.GetCategoryList = async function (flag, CategoryData, page, size)  {

	try{
		//パラメータ確認
		if(!CategoryData || CategoryData.length === 0){ return({ error : "カテゴリデータがありません。" }); }

		const from = (page === 1 || !page) ?  0 : Number((Number(page) - 1) * Number(size) ) ;
		size = size ? size : 50;

		//商品コードリスト
		let categoryids = [];

		//カテゴリIDごとに対象商品を検索
		for(const x of CategoryData){
			if(x.categoryid){
				categoryids.push(x.categoryid)
			}
		}
		//重複削除
		categoryids = categoryids.filter((x, i, self) => self.indexOf(x) === i);

		//クエリ
		const Query = `query searchItemCategory($flag: String!, $categoryids: [String]!, $from: Int!, $size: Int!) {
			searchItemCategory(flag: $flag, categoryids: $categoryids, from: $from, size: $size) {
				items{
					code
					name
					m_code
					m_name
					price
					title
					text_1
					text_2
					text_3
					text_4
					text_5
					zaiko
				}
				scannedCount
			}
		}`;
		const Data = {
			flag        : flag,
			categoryids : categoryids,
			from        : from,
			size        : size,
		};
		const ref     = await API.graphql(graphqlOperation(Query, Data));
		const Items   = ref.data.searchItemCategory.items;
		const Count   = ref.data.searchItemCategory.scannedCount;

		return ({
			items: Items,
			count: Count,
			error: null,
		});
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

Item.GetCategoryListV2 = async function (flag, CategoryData, page, size, sortname)  {

	try{
		//パラメータ確認
		if(!CategoryData || CategoryData.length === 0){ return({ error : "カテゴリデータがありません。" }); }

		const from = (page === 1 || !page) ?  0 : Number((Number(page) - 1) * Number(size) ) ;
		size = size ? size : 50;

		let sort  = '日付';
		let order = '降順';

		if(sortname === '新しい順'){
			sort  = '日付';
			order = '降順';
		}
		else if(sortname === '安い順'){
			sort  = '価格';
			order = '昇順';
		}
		else if(sortname === '高い順'){
			sort  = '価格';
			order = '降順';
		}
		else if(sortname === '商品名順（昇順）'){
			sort  = '商品名';
			order = '昇順';
		}
		else if(sortname === '商品名順（降順）'){
			sort  = '商品名';
			order = '降順';
		}

		if(sort === '価格'){
			sort = 'price';
		}
		else if(sort === '日付'){
			sort = 'date_add'; //date_editから変更 2020.10.22
		}
		else if(sort === '商品名'){
			sort = 'name.keyword';
		}

		if(order === '降順'){
			order = 'desc';
		}
		else if(order === '昇順'){
			order = 'asc';
		}

		//商品コードリスト
		let categoryids = [];

		//カテゴリIDごとに対象商品を検索
		for(const x of CategoryData){
			if(x.categoryid){
				categoryids.push(x.categoryid)
			}
		}
		//重複削除
		categoryids = categoryids.filter((x, i, self) => self.indexOf(x) === i);

		//クエリ
		const Query = `query searchItemCategoryV2($flag: String!, $categoryids: [String]!, $from: Int!, $size: Int!, $sort: String!, $order: String!) {
			searchItemCategoryV2(flag: $flag, categoryids: $categoryids, from: $from, size: $size, sort: $sort, order: $order) {
				items{
					code
					name
					m_code
					m_name
					price
					title
					text_1
					text_2
					text_3
					text_4
					text_5
					flag_3
					zaiko
					zaiko_flag
				}
				scannedCount
			}
		}`;
		const Data = {
			flag        : flag,
			categoryids : categoryids,
			from        : from,
			size        : size,
			sort        : sort,
			order       : order,
		};
		const ref     = await API.graphql(graphqlOperation(Query, Data));
		console.log(ref);
		const Items   = ref.data.searchItemCategoryV2.items;
		const Count   = ref.data.searchItemCategoryV2.scannedCount;

		return ({
			items: Items,
			count: Count,
			error: null,
		});
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}


export { Item };
