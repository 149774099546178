import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Form,
	Button,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class Header extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			characterShow  : false,
			itemShow       : false,
			keywordShow    : false,
			cognitoid      : '',
		}
		this._getData     = this._getData.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	async _getData() {
		//ログインの確認
		try{
			//Cognito IDの取得
			const user         = await API.Auth.currentAuthenticatedUser();
			const cognitoid    = user.username;

			//ログイン済みの場合フラグ
			if(cognitoid){
				this.setState({
					cognitoid  : cognitoid,
				 });
			}
		}
		catch(error) {
			//
		}
	}
	async handleSubmit(event){
		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;

			//Formから直接取得する項目
			const keyword       = formObj.keyword.value;

			//モーダル削除
			this.setState({
				keywordShow : false,
			});

			//検索画面へ
			//this.props.history.push('/search/' + keyword + '/');
			window.location.href = '/search/' + keyword + '/';

			//if(this.props.reload){
			//	this.props.reload(keyword);
			//}
		}
		catch(error) {
			//
		}
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		return (
  <header className="fixed-top cosme_top">
    <Container>
        <div className="head_nav">
              <ul className="flexbox-start">
							<li className={this.state.cognitoid ? 'mailmagazine members' : 'mailmagazine'}><a href="/member/"><i className="fas fa-envelope-open-text"></i><br /> 会員登録 </a></li>
							<li><a href="/guide/"><i className="fas fa-book"></i><br /> GUIDE </a></li>
							<li><a href={this.state.cognitoid ? '/logout/' : '/login/'}><i className="fas fa-lock"></i><br /> {this.state.cognitoid ? 'LOGOUT' : 'LOGIN'}< /a></li>
							{/*<li><a href="/mypage/wishlist/"><i className="fas fa-heart"></i><br /> WISH LIST </a></li>*/}
							<li><a href="/mypage/"><i className="far fa-smile"></i><br /> MY PAGE </a></li>
							<li><a href="/cart/"><i className="fas fa-shopping-cart"></i><br /> CART <br /></a></li>
	<li className="smp_search"><input type="checkbox" id="togglenav_smp" /><label htmlFor="togglenav_smp" className=""><i className="fas fa-search"></i><br /> SEARCH </label>
    <input type="checkbox" id="togglenav_smp" />
    <div className="drawer04">
				<h3>キーワードで探す</h3>
<Form onSubmit={e => this.handleSubmit(e)}>
		<Form.Control type="text" name="keyword" size="lg" placeholder="" required pattern=".{1,255}" />
		<Button size="sm" type="submit" className="btn-blue mt-3 btn-block" >
			検索
		</Button>
</Form>
				<h3>アイテムで探す</h3>
		<ul className="drawer-menu cate-character-drawer">
					<h4 className="catettl">フルプルコスメ</h4>
						<ul className="cate-item-drawer pb-3">
							{/*<li><a href="/category/フルプルコスメ-フルプルクリーム/"><i className="fas fa-caret-right"></i> フルプルクリーム</a></li>
							<li><a href="/category/フルプルコスメ-フルプルコロン/"><i className="fas fa-caret-right"></i> フルプルコロン</a></li> 
							<li><a href="/category/フルプルコスメ-フルプルハンド&ネイルクリーム/"><i className="fas fa-caret-right"></i> フルプルハンド&ネイルクリーム</a></li>*/}
							<li><a href="/category/フルプルコスメ-フルプルフェイスマスク/"><i className="fas fa-caret-right"></i> フルプルフェイスマスク</a></li>
							<li><a href="/category/フルプルコスメ-フルプルバスソルト/"><i className="fas fa-caret-right"></i> フルプルバスソルト</a></li>
							<li><a href="/category/ギフト/"><i className="fas fa-caret-right"></i> ギフトセット</a></li>
					</ul>
					{/*<h4 className="catettl">贅沢美肌堂</h4>
					<ul className="cate-item-drawer pb-3">
							<li><a href="/others/zeitakubihadado"><i className="fas fa-caret-right"></i> 抹茶de美肌</a></li>
						</ul>*/}
						</ul>
    </div>
	</li>
	</ul>
            </div>
      <div className="search-nav">
 <div className="head-logo hp"><a href="/"><Image src="/svg/logo_header_cosme.svg" /></a></div>
</div>
</Container>


</header>
		);
	}
}


export default withRouter(Header);
